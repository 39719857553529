import { useEffect } from "react";
import { Form, Select, Row, Col } from "antd";

import Spinner from "../../components/spinner";
import { useAppContext } from "../../components/contexts/appContext";

const { Item } = Form;

const Profile = () => {
  const { loadCurrentUser, loadingUser } = useAppContext();
  const [form] = Form.useForm();

  useEffect(() => {
    loadCurrentUser(true);
  }, []);

  return loadingUser ? (
    <Spinner />
  ) : (
    <Row>
      <Col sm={8}>
        <Form form={form} layout="vertical" initialValues={{ currency: "INR" }}>
          <Item name="currency" label="Currency">
            <Select
              options={[
                { label: "USD", value: "USD" },
                { label: "INR", value: "INR" },
              ]}
            />
          </Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Profile;
