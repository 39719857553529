import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Switch, Select, Row, Col, Button, InputNumber } from "antd";

import styles from "./form.module.scss";

const { Item } = Form;

interface IPaymentDetailsProps {
  mode: string | null;
  onSubmit: (data: any) => void;
  formData: any;
  setActiveKey: (arg: string) => void;
  isSubmitting: boolean;
}

const PaymentDetails = ({
  mode,
  onSubmit,
  formData,
  setActiveKey,
  isSubmitting,
}: IPaymentDetailsProps) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const watchCurrency = Form.useWatch("currency", form);
  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue(formData);
    }
  }, [mode]);

  const beforeSubmit = (data: any) => {
    onSubmit({ ...data, amount: Number(data.amount) });
  };

  return (
    <>
      <Row>
        <Col sm={12}>
          <Form
            onFinish={beforeSubmit}
            form={form}
            layout="vertical"
            initialValues={{ currency: "INR" }}
            disabled={isSubmitting}
          >
            <div
              style={{
                display: "flex",
                gap: 20,
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <Item
                name="isPayable"
                valuePropName="checked"
                style={{ marginBottom: 0 }}
              >
                <Switch style={{ verticalAlign: "center" }} />
              </Item>
              <span>Collect fees for this event?</span>
            </div>
            <Item name="currency" label="Currency">
              <Select
                options={[
                  { label: "USD", value: "USD" },
                  { label: "INR", value: "INR" },
                ]}
                style={{ width: 150 }}
              />
            </Item>
            <Item
              name="amount"
              label="Fee"
              rules={[
                {
                  required: form.getFieldValue("isPayable"),
                  message: "Please enter the amount you want to charge",
                },
                {
                  validator: async (_, val) => {
                    if (form.getFieldValue("isPayable") && !val) {
                      return Promise.reject(
                        "Please enter the amount you want to charge"
                      );
                    }
                  },
                },
              ]}
            >
              <InputNumber
                precision={2}
                min={0}
                prefix={
                  watchCurrency === "INR" ? (
                    <span>&#8377;</span>
                  ) : (
                    <span>&#36;</span>
                  )
                }
                style={{ width: 150 }}
                controls={false}
              />
            </Item>
          </Form>
        </Col>
      </Row>
      <div className={styles["submit-btn-cont"]}>
        <Button
          onClick={() => {
            if (mode === "edit") {
              setActiveKey("0");
            } else {
              navigate("/events/event-types");
            }
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={form.submit} type="primary" loading={isSubmitting}>
          {mode === "edit" ? "Save and update" : "Next"}
        </Button>
      </div>
    </>
  );
};

export default PaymentDetails;
