import { useMemo, useEffect, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Collapse, message } from "antd";
import axios from "axios";

import Step1 from "./step1";
import Step2 from "./step2";
import InviteeQuestions from "./inviteeQuestions";
import PaymentDetails from "./paymentDetails";
import { getApiErrorMsg } from "../../../utils/object-util";
import { eventTypeDetails } from "../../../constants";
import Micons from "../../../components/customAntd/micons";

import styles from "./form.module.scss";

const Forms = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>();
  const [activeKey, setActiveKey] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [mode, type, id, isCreate] = useMemo(() => {
    return [
      searchParams.get("mode"),
      searchParams.get("type"),
      searchParams.get("id"),
      searchParams.get("isCreate"),
    ];
  }, [searchParams]);

  useEffect(() => {
    if (mode === "create") {
      if (id) {
        setActiveKey("2");
      } else {
        setActiveKey("1");
      }
    } else if (mode === "edit" && isCreate === "true") {
      setActiveKey("3");
    } else if (localStorage.getItem("eventForm")) {
      setActiveKey("1");
    } else {
      setActiveKey("0");
    }
  }, [mode, id, isCreate]);

  useEffect(() => {
    if (id) {
      axios
        .get(`events/${id}`)
        .then((resp) => {
          if (resp.data.data) {
            setFormData(resp.data.data);
          }
        })
        .catch((err) => {
          message.error({
            content: getApiErrorMsg(err),
            key: "fetch-error",
            duration: 2,
          });
        });
    }
  }, [id]);

  useEffect(() => {
    if (id && isCreate === "true") {
      navigate(`/events/form?mode=edit&type=${type}&id=${id}&isCreate=false`);
    }
  }, []);

  const onSubmit = (data: any) => {
    setIsSubmitting(true);
    const apiMethod = id ? axios.put : axios.post;
    const apiUrl =
      mode === "edit" || (mode === "create" && id) ? `events/${id}` : "events";
    if (!id && type) data.type = type;
    if (data.link) {
      data.link = data.link.trim();
    }
    apiMethod(apiUrl, data)
      .then((response) => {
        if (response.data.data) {
          if (mode === "create" && !id) {
            navigate(
              `/events/form?mode=create&type=${type}&id=${response.data.data.id}&isCreate=true`
            );
          } else if (mode === "create" && id) {
            navigate(
              `/events/form?mode=edit&type=${type}&id=${response.data.data.id}&isCreate=true`
            );
          }
        }
        if (mode === "edit") {
          setActiveKey("0");
        }
        setFormData(response.data.data);
        message.success({
          content: "Changes saved",
          key: "success",
          duration: 2,
        });
      })
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "error",
          duration: 2,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const items = [
    {
      key: "1",
      label: "What event is this?",
      children: (
        <Step1
          id={id}
          type={type}
          mode={mode}
          onSubmit={onSubmit}
          formData={formData}
          setActiveKey={setActiveKey}
          isSubmitting={isSubmitting}
        />
      ),
      className: styles["step-one"],
    },
    ...((mode === "create" && id) || (mode === "edit" && id)
      ? [
          {
            key: "2",
            label: "When can people book this event?",
            children: (
              <Step2
                formData={formData}
                mode={mode}
                onSubmit={onSubmit}
                id={id}
                type={type}
                setActiveKey={setActiveKey}
                isSubmitting={isSubmitting}
              />
            ),
            style: { padding: 0 },
            className: styles["step-two"],
          },
        ]
      : []),
    ...(mode === "edit" && id
      ? [
          {
            key: "3",
            label: "Invitee Questions",
            forceRender: true,
            children: (
              <InviteeQuestions
                mode={mode}
                id={id}
                type={type}
                setActiveKey={setActiveKey}
              />
            ),
            className: styles["invitee-questions"],
          },
          {
            key: "4",
            label: "Payment Details",
            children: (
              <PaymentDetails
                mode={mode}
                onSubmit={onSubmit}
                formData={formData}
                setActiveKey={setActiveKey}
                isSubmitting={isSubmitting}
              />
            ),
            className: styles["payment-details"],
          },
        ]
      : []),
  ];

  const handleOpen = (key: any) => {
    setActiveKey(key);
  };

  return (
    <>
      <div>
        <Link
          to={mode === "create" && !id ? "/events/event-types" : "/events"}
          className={styles["back"]}
        >
          <Micons icon="arrow_back" />
          Back
        </Link>
        <h1 className={styles["event-type-details"]}>
          {`${mode === "edit" ? "Edit" : "Add"} ${
            eventTypeDetails.find((el) => el.type === type)?.name
          } Event Type`}
        </h1>
      </div>

      <Collapse
        items={items}
        size="large"
        accordion
        bordered={false}
        ghost
        className={styles["wrapper"]}
        activeKey={activeKey}
        onChange={handleOpen}
      ></Collapse>
    </>
  );
};

export default Forms;
