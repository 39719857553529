import { useEffect } from "react";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import { message } from "antd";
import axios from "axios";

import { getApiErrorMsg, getApiResponseMsg } from "../../utils/object-util";
import { useAppContext } from "../../components/contexts/appContext";
import Spinner from "../../components/spinner";

type Type = "zoom" | "google-calendar" | "stripe";

const IntegrationRedirects = () => {
  const { pathname } = useLocation();
  const { loadCurrentUser } = useAppContext();
  const type: Type =
    pathname === "/integrate-zoom"
      ? "zoom"
      : pathname === "/integrate-gmeet"
      ? "google-calendar"
      : "stripe";

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get("code");
  const typeParam = searchParams.get("state");

  useEffect(() => {
    if (code) {
      const apimethod = type === "google-calendar" ? axios.put : axios.post;
      const apiUrl = type === "google-calendar" ? type : `${type}/token`;
      apimethod(apiUrl, { code })
        .then((resp) => {
          message.success({
            content: getApiResponseMsg(resp),
            key: "google",
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: getApiErrorMsg(err),
            key: "error",
            duration: 2,
          });
        })
        .finally(() => {
          if (typeParam && typeParam !== "integration") {
            if (typeParam === "event") {
              const eventType =
                localStorage.getItem("integration-callback-event-type") || "";
              const eventId = localStorage.getItem(
                "integration-callback-event-id"
              );
              if (typeParam === "event") {
                loadCurrentUser(); // fetching user after saving code
              }
              navigate(
                `/events/form?type=${eventType}&${
                  eventId ? `mode=edit&id=${eventId}` : `mode=create`
                }`,
                { replace: true }
              );
            } else {
              navigate({
                pathname: "/payout",
              });
            }
          } else {
            navigate("/integration", { replace: true });
          }
        });
    }
  }, [code]);

  return <Spinner />;
};

export default IntegrationRedirects;
