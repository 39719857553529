import { isString } from "./type-util";

/**
 * Getting the error response message
 *
 * @param   {Object}    error      The error response.
 * @returns {String}
 */
export function getApiErrorMsg(error: any, message?: string) {
  if (isString(error?.response?.data?.message)) {
    return error.response.data.message;
  } else {
    return message || "Something went wrong. Please try again";
  }
}

/**
 * Getting the success response message
 *
 * @param   {Object}    response      The success response.
 * @returns {String}
 */
export function getApiResponseMsg(resp: any, message?: string) {
  if (isString(resp?.data?.message)) {
    return resp.data.message;
  } else if (isString(resp?.message)) {
    return resp.message;
  } else {
    return message || "Successfully Executed!";
  }
}
