import { useState, useEffect } from "react";
import { Modal, Calendar, Button, Form, TimePicker, Row, Col } from "antd";
import dayjs, { type Dayjs } from "dayjs";
import type { FormInstance } from "antd/es/form";

import { IAvailabilities, IAvailabilityTimes, ISchedule } from "../index";
import Micons from "../../../../components/customAntd/micons";

import styles from "./dateOverrideCalendar.module.scss";

interface IDateOverrideCalendarProps {
  isOpen: boolean;
  onCancel: () => void;
  selectedSchedule: ISchedule;
  title: string;
  form: FormInstance;
  setIsOpen: (isOpen: boolean) => void;
  dateOverrides: IAvailabilities[];
  selectedDate: string | undefined;
  setIsEdit: (arg: boolean) => void;
  isSubmitting: boolean;
  setIsSubmitting: (arg: boolean) => void;
  handleSubmit: (data: any) => void;
}

const DateOverrideCalendar = ({
  isOpen,
  onCancel,
  title,
  selectedSchedule,
  form,
  setIsOpen,
  dateOverrides,
  selectedDate,
  setIsEdit,
  isSubmitting,
  setIsSubmitting,
  handleSubmit,
}: IDateOverrideCalendarProps) => {
  const [localDate, setLocalDate] = useState<Dayjs>(dayjs());
  const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
  const [submit, setSubmit] = useState<any>(null);

  useEffect(() => {
    if (selectedSchedule && selectedDates.length > 0) {
      const lastAddedDate = selectedDates[selectedDates.length - 1];
      if (!form.getFieldsValue(["availabilityTimes"]).availabilityTimes) {
        const check = selectedSchedule.availabilities.find(
          (el) =>
            el.type === "Date" &&
            dayjs(el?.date).format("YYYY-MM-DD") === lastAddedDate
        );
        if (check) {
          const availabilityTimes = check.availabilityTimes.map((el) => ({
            startTime: dayjs(el.startTime),
            endTime: dayjs(el.endTime),
          }));
          form.setFieldsValue({ availabilityTimes: availabilityTimes });
        } else {
          const weekday = selectedSchedule.availabilities.find(
            (el) =>
              el.type === "WeekDay" &&
              dayjs(lastAddedDate, "YYYY-MM-DD").format("dddd") === el.day
          );
          if (weekday) {
            form.setFieldsValue({
              availabilityTimes: weekday.availabilityTimes.map((el) => ({
                startTime: dayjs(el.startTime),
                endTime: dayjs(el.endTime),
              })),
            });
          } else {
            form.setFieldsValue({
              availabilityTimes: [
                {
                  startTime: dayjs().set("hour", 9).set("minute", 0),
                  endTime: dayjs().set("hour", 17).set("minute", 0),
                },
              ],
            });
          }
        }
      }
    }
  }, [selectedDates]);

  useEffect(() => {
    if (selectedDate) {
      setSelectedDates([dayjs(selectedDate).format("YYYY-MM-DD")]);
      setIsOpen(true);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (submit) {
      handleSubmit(submit);
      setSubmit(null);
    }
  }, [submit]);

  useEffect(() => {
    if (!isSubmitting) {
      setSelectedDates([]);
      setIsOpen(false);
    }
  }, [isSubmitting]);

  const onSubmit = (data: any) => {
    const getMap = (arr: IAvailabilityTimes[]) => {
      return arr.map((time: any) => ({
        startTime: dayjs(time.startTime).format(),
        endTime: dayjs(time.endTime).format(),
      }));
    };

    if (selectedSchedule) {
      const { availabilityTimes } = data;
      let selectedDatesCopy = [...selectedDates];
      const availabilities: any = selectedSchedule.availabilities.map((el) => {
        if (el.type === "WeekDay") {
          return {
            type: "WeekDay",
            day: el.day,
            availabilityTimes: el.availabilityTimes,
          };
        } else {
          const date = selectedDates.find((dateEl) => {
            return dayjs(el.date).format("YYYY-MM-DD") === dateEl;
          });

          if (date) {
            selectedDatesCopy = selectedDatesCopy.filter((el) => el !== date);
          }

          return {
            type: el.type,
            date: date ? dayjs(date, "YYYY-MM-DD").format() : el.date,
            availabilityTimes: date ? availabilityTimes : el.availabilityTimes,
          };
        }
      });

      [
        ...(dateOverrides.length === 0 ? selectedDates : selectedDatesCopy),
      ].forEach((date) => {
        availabilities.push({
          type: "Date",
          date: dayjs(date, "YYYY-MM-DD").format(),
          availabilityTimes: getMap(availabilityTimes),
        });
      });
      setIsSubmitting(true);
      setIsEdit(true);
      setSubmit({
        ...selectedSchedule,
        availabilities: availabilities.sort((a: any, b: any) => {
          if (a.type !== "Date" || b.type !== "Date") {
            return 0;
          } else return dayjs(a.date) > dayjs(b.date) ? 1 : -1;
        }),
      });
    }
  };

  return (
    <Modal
      open={isOpen}
      title={title}
      okText="Apply"
      cancelText="Cancel"
      width={400}
      closeIcon={null}
      onCancel={() => {
        onCancel();
        setSelectedDates([]);
      }}
      cancelButtonProps={{ disabled: isSubmitting }}
      onOk={form.submit}
      okButtonProps={{ disabled: selectedDates.length === 0 || isSubmitting }}
      destroyOnClose
      className={styles["calendar-modal"]}
    >
      <Calendar
        className={styles["calendar"]}
        fullscreen={false}
        mode="month"
        value={localDate}
        headerRender={({ value }) => {
          const month = value.month();
          const year = value.year();
          const handlePrevMonth = () => {
            setLocalDate(dayjs(value).subtract(1, "month"));
          };

          const handleNextMonth = () => {
            setLocalDate(dayjs(value).add(1, "month"));
          };

          return (
            <div className={styles["custom-header"]}>
              <p>{`${dayjs(value).format("MMM")} ${year}`}</p>
              <div style={{ display: "flex", gap: 20 }}>
                <Button
                  icon={<Micons icon="navigate_before" />}
                  onClick={() => handlePrevMonth()}
                  disabled={
                    month === dayjs().month() && year === dayjs().year()
                  }
                ></Button>
                <Button
                  icon={<Micons icon="navigate_next" />}
                  onClick={() => {
                    handleNextMonth();
                  }}
                ></Button>
              </div>
            </div>
          );
        }}
        onSelect={(date: Dayjs) => {
          if (date > dayjs()) {
            setLocalDate(date);
            setSelectedDates((prev) => {
              const selectedDate = dayjs(date).format("YYYY-MM-DD");
              const index = prev.findIndex((el) => el === selectedDate);
              if (index != -1) {
                prev.splice(index, 1);
                return [...prev];
              } else {
                return [...prev, selectedDate];
              }
            });
          }
        }}
        disabledDate={(date) => {
          return (
            date.format("MM") !== localDate?.format("MM") || date < dayjs()
          );
        }}
        fullCellRender={(date: Dayjs) => {
          return (
            date.format("MM") === localDate?.format("MM") && (
              <div
                className={`ant-picker-cell-inner ant-picker-cell-calendar-date ${
                  selectedDates.includes(date.format("YYYY-MM-DD"))
                    ? styles["selected-date"]
                    : ""
                }`}
              >
                {date.format("D")}
              </div>
            )
          );
        }}
      />
      {selectedDates.length > 0 && (
        <div className={styles["list-wrapper"]}>
          <p className={styles["heading"]}>What hours are you available?</p>
          <Form form={form} onFinish={onSubmit} preserve={false}>
            <Form.List name="availabilityTimes">
              {(innerFields, { add, remove }) => {
                return (
                  <Row>
                    <Col sm={22}>
                      {innerFields.map((innerField, key) => {
                        return (
                          <>
                            <div key={key} className={styles["slots"]}>
                              <Form.Item name={[innerField.name, "startTime"]}>
                                <TimePicker
                                  suffixIcon={null}
                                  format="hh:mm A"
                                  minuteStep={15}
                                  showNow={false}
                                  allowClear={false}
                                />
                              </Form.Item>
                              <span style={{ margin: 5 }}>-</span>
                              <Form.Item
                                name={[innerField.name, "endTime"]}
                                style={{ marginRight: 10 }}
                              >
                                <TimePicker
                                  suffixIcon={null}
                                  format="hh:mm A"
                                  minuteStep={15}
                                  showNow={false}
                                  allowClear={false}
                                />
                              </Form.Item>
                              <Button
                                icon={<Micons icon="delete" />}
                                onClick={() => {
                                  remove(innerField.name);
                                }}
                              ></Button>
                            </div>
                          </>
                        );
                      })}
                      {innerFields.length === 0 && (
                        <p style={{ color: "rgb(26,26,26)" }}>Unavailable</p>
                      )}
                    </Col>
                    <Col sm={2}>
                      <Button
                        icon={<Micons icon="add" />}
                        onClick={() => {
                          const endTime = form.getFieldValue([
                            "availabilityTimes",
                            innerFields.length - 1,
                            "endTime",
                          ]);
                          add({
                            startTime: endTime
                              ? endTime
                              : dayjs().set("hour", 9).set("minute", 0),
                            endTime: endTime
                              ? dayjs(endTime).add(1, "hour")
                              : dayjs().set("hour", 17).set("minute", 0),
                          });
                        }}
                      ></Button>
                    </Col>
                  </Row>
                );
              }}
            </Form.List>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default DateOverrideCalendar;
