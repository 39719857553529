import { Checkbox as AntdCheckbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import styles from "./checkbox.module.scss";

interface CheckboxProps {
  title?: string | React.ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: CheckboxChangeEvent) => void;
}

export const Checkbox = ({
  title,
  checked,
  disabled,
  onChange,
}: CheckboxProps) => {
  return (
    <AntdCheckbox
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      className={styles["checkbox"]}
    >
      {title}
    </AntdCheckbox>
  );
};
