import { createContext, useContext } from "react";

interface IAppContextProps {
  loadingUser: boolean;
  isUserLoggedIn: boolean;
  loadCurrentUser: (hideLoader?: boolean) => void;
  user: { [key: string]: any } | undefined;
  setIsUserLoggedIn: (val: boolean) => void;
  isAutoscalLandingPage?: boolean;
  isProfilePage?: boolean;
  profileToken?:string ,
}

const AppContext = createContext<IAppContextProps>({
  isUserLoggedIn: false,
  user: undefined,
  loadingUser: false,
  isAutoscalLandingPage: false,
  isProfilePage: false,
  loadCurrentUser: () => null,
  setIsUserLoggedIn: () => null,
  profileToken:undefined,
});

export const useAppContext = () => {
  return useContext(AppContext);
};

export default AppContext;
