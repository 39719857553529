import { useState, useEffect } from "react";
import {
  Dropdown,
  MenuProps,
  Row,
  Col,
  message,
  Button,
  Segmented,
  Tooltip,
  Modal,
  Select,
} from "antd";
import { SegmentedValue } from "antd/es/segmented";
import axios, { AxiosError, AxiosResponse } from "axios";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import Wrapper from "../../../components/wrapper";
import Micons from "../../../components/customAntd/micons";
import SetWeeklyHours from "./setWeeklyHours";
import DateOverride from "./dateOverride";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";
import ScheduleForm from "./scheduleForm";
import { timeZones } from "../../../constants";

import styles from "./schedule.module.scss";

export interface IAvailabilityTimes {
  startTime: string;
  endTime: string;
  id: string;
  availabilityId: string;
}

export interface IAvailabilities {
  type: "WeekDay" | "Date";
  day?:
    | "Sunday"
    | "Monday"
    | "Tuesday"
    | "Wednesday"
    | "Thursday"
    | "Friday"
    | "Saturday";
  date?: string;
  availabilityTimes: IAvailabilityTimes[];
  id: string;
}

export interface ISchedule {
  id: string;
  name: string;
  availabilities: IAvailabilities[];
  isDefault: boolean;
  timezone: string;
  isCustom: boolean;
}

interface IScheduleProps {
  isEvent?: boolean;
  customSchedule?: ISchedule;
  setCustomSchedule?: (args: any) => void;
  saveNewSchedule?: (args: ISchedule) => void;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const Schedule = ({
  isEvent,
  customSchedule,
  setCustomSchedule,
  saveNewSchedule,
}: IScheduleProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [view, setView] = useState<SegmentedValue>("list");
  const [schedules, setSchedules] = useState<Array<ISchedule>>([]);
  const [selectedSchedule, setSelectedSchedule] = useState<ISchedule>();
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [submitData, setSubmitData] = useState<any>();

  //Dropdown options for schedule actions
  const scheduleActions: MenuProps["items"] = [
    {
      key: "edit",
      label: (
        <>
          <Micons icon="edit" />
          <span>Edit name</span>
        </>
      ),
    },
    {
      key: "clone",
      label: (
        <>
          <Micons icon="copy" />
          <span>Clone</span>
        </>
      ),
    },
    ...(schedules.length > 1
      ? [
          {
            key: "delete",
            label: (
              <>
                <Micons icon="delete" />
                <span>Delete</span>
              </>
            ),
          },
        ]
      : []),
  ];

  //Get all schedules
  useEffect(() => {
    if (!isEvent) {
      axios
        .post("schedules/all")
        .then((response) => {
          if (response?.data?.data) {
            setSchedules(response.data.data);
            setSelectedSchedule(
              // response.data.data.find((el: ISchedule) => el.isDefault)
              response.data.data[0]
            );
          } else {
            message.error({
              content: getApiResponseMsg(response),
              key: "resp-error",
              duration: 2,
            });
          }
        })
        .catch((err) => {
          message.error({
            content: getApiErrorMsg(err),
            key: "error",
            duration: 2,
          });
        });
    } else {
      if (customSchedule) setSelectedSchedule(customSchedule);
    }
  }, [isEvent, customSchedule]);

  //Dropdown options handler for schedule actions: edit, clone and delete
  const handleOnClick = (info: any) => {
    if (selectedSchedule) {
      const { key } = info;
      if (key === "edit") {
        setIsEdit(true);
        setIsOpen(true);
      } else if (key === "delete") {
        setIsDeleteModalOpen(true);
      } else if (key === "clone") {
        //create new schedule with (clone) appended to title
        handleFormSubmit(
          {
            name: `${selectedSchedule.name} (clone)`,
            availabilities: selectedSchedule.availabilities.map((item: any) => {
              const newItem = { ...item };
              if (item.type === "Date") {
                delete newItem.day;
              } else {
                delete newItem.date;
              }
              return newItem;
            }),
            timezone: selectedSchedule.timezone,
          },
          true
        );
      }
    }
  };

  //List/Calendar view
  const changeView = (value: SegmentedValue) => {
    setView(value);
  };

  //Select schedule from dropdown list of schedules
  const selectSchedule = (info: any) => {
    let currentIndex = 3;
    const currentSchedule: ISchedule | undefined = schedules.find(
      (schedule, index) => {
        currentIndex = index;
        return schedule.id === info.key;
      }
    );

    setSelectedSchedule(currentSchedule);

    if (currentSchedule) {
      setSchedules((prev) => {
        prev.splice(currentIndex, 1);
        prev.push(prev[2]);
        prev[2] = currentSchedule;
        return [...prev];
      });
    }
  };

  //For creating, editing and cloning a schedule. Name only.
  const handleFormSubmit = (
    data: {
      name: string;
      availabilities?: IAvailabilities[];
      isCustom?: boolean;
      timezone?: string;
    },
    isClone: boolean
  ) => {
    // setIsSubmitting(true);
    const isNewScheduleForEvent = isEvent && !isEdit;
    if (isNewScheduleForEvent) {
      data.isCustom = false;
      data.availabilities = selectedSchedule?.availabilities;
      data.timezone = selectedSchedule?.timezone;
    }
    // if(!data.timezone) data.timezone = time
    if (isNewScheduleForEvent || !isEvent) {
      const editing = !!(isEdit && selectedSchedule && !isClone);
      const apiMethod = editing ? axios.put : axios.post;
      const apiUrl = editing ? `schedules/${selectedSchedule.id}` : "schedules";
      apiMethod(apiUrl, data)
        .then((resp: AxiosResponse) => {
          if (resp?.data?.data) {
            const newSchedule = resp.data.data;
            if (isNewScheduleForEvent) {
              // For events form
              if (saveNewSchedule) saveNewSchedule(newSchedule);
            } else {
              // For availability screen
              setSchedules((prev: any) => {
                return isEdit
                  ? prev.map((item: ISchedule) => {
                      return newSchedule.id === item.id ? newSchedule : item;
                    })
                  : [prev[0], newSchedule, ...prev.slice(1, prev.length)];
              });
              setSelectedSchedule(newSchedule);
            }
            message.success({
              content: getApiResponseMsg(resp),
              key: "success",
              duration: 2,
            });
          }
        })
        .catch((err: AxiosError) => {
          message.error({
            content: getApiErrorMsg(
              err,
              "Something went wrong while creating a new schedule."
            ),
            key: "create-error",
            duration: 2,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsOpen(false);
          setIsEdit(false);
        });
    } else {
      if (setCustomSchedule) setCustomSchedule(data);
      setIsSubmitting(false);
      setIsOpen(false);
      setIsEdit(false);
    }
  };

  //Delete schedule
  const handleDelete = () => {
    setIsSubmitting(true);
    if (selectedSchedule) {
      axios
        .delete(`schedules/${selectedSchedule.id}`)
        .then((response) => {
          const schedulesList = schedules.filter(
            (item) => item.id !== response.data.data.id
          );
          setSchedules(schedulesList);
          setSelectedSchedule(schedulesList[0]);
          message.success({
            content: "Successfully deleted schedule",
            key: "delete-success",
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: getApiErrorMsg(err),
            key: "error",
            duration: 2,
          });
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsDeleteModalOpen(false);
        });
    }
  };

  useEffect(() => {
    if (submitData) {
      handleFormSubmit(submitData, false);
      setSubmitData(null);
    }
  }, [submitData]);

  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // console.log(timeZone);

  return selectedSchedule ? (
    <>
      <Modal
        title={`Delete ${selectedSchedule?.name}?`}
        open={isDeleteModalOpen}
        okText="Delete"
        cancelText="Cancel"
        cancelButtonProps={{
          onClick: () => {
            setIsDeleteModalOpen(false);
          },
          disabled: isSubmitting,
        }}
        okButtonProps={{ onClick: handleDelete, disabled: isSubmitting }}
        className={styles["delete-confirmation-modal"]}
        closeIcon={null}
        width={400}
      >
        This action cannot be undone. Any event types associated with this
        schedule will be set to custom availability with these hours.
      </Modal>
      <ScheduleForm
        isOpen={isOpen}
        isEdit={isEdit}
        selectedSchedule={selectedSchedule}
        onSubmit={(val) => {
          handleFormSubmit(val, false);
        }}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        onClose={() => {
          setIsOpen(false);
          setIsEdit(false);
        }}
        isEvent={isEvent}
      />
      {!isEvent && (
        <>
          <h1 className={styles.heading}>Availability Options</h1>
          <p className={styles.description}>
            Choose a schedule below to edit or create a new one that you can
            apply to your event types
          </p>
          <div className={styles["schedules"]}>
            <div>
              {[
                // showing only 3 schedules and the rest in dropdown list
                ...(schedules.length <= 3 ? schedules : schedules.slice(0, 3)),
              ].map((schedule) => (
                <Button
                  key={schedule.id}
                  icon={<Micons icon="event_note" />}
                  onClick={() => {
                    if (
                      selectedSchedule &&
                      schedule.id !== selectedSchedule.id
                    ) {
                      setSelectedSchedule(schedule);
                    }
                  }}
                  className={`${
                    selectedSchedule.id === schedule.id
                      ? styles["selected"]
                      : ""
                  }`}
                >
                  {schedule.name}
                </Button>
              ))}
              {schedules.length > 3 && (
                <Dropdown
                  trigger={["click"]}
                  menu={{
                    items: [...schedules.slice(3, schedules.length)].map(
                      // Not using a seperate state
                      (schedule) => ({
                        key: schedule.id,
                        label: schedule.name,
                      })
                    ),
                    onClick: selectSchedule,
                  }}
                >
                  <a
                    onClick={(e) => e.preventDefault()}
                    style={{ display: "flex", paddingTop: 5 }}
                  >
                    {`${schedules.length - 3} more`}
                    <Micons icon="keyboard_arrow_down" />
                  </a>
                </Dropdown>
              )}
            </div>
            <Button
              type="primary"
              className={styles["add-schedule-button"]}
              icon={<Micons icon="add" />}
              onClick={() => {
                setIsEdit(false);
                setIsOpen(true);
              }}
            >
              New schedule
            </Button>
          </div>
        </>
      )}
      <Wrapper>
        <div className={styles["schedule-details"]}>
          {!isEvent && (
            <Row>
              <Col sm={23}>
                <h3>{selectedSchedule.name}</h3>
                {selectedSchedule.isDefault && (
                  <div className={styles["default-cont"]}>
                    <Micons icon="star" type="filled" />
                    <p>default schedule</p>
                  </div>
                )}
                {/* add default schedule here if it is */}
              </Col>
              <Col sm={1}>
                <Tooltip
                  title={`${selectedSchedule.name} Settings`}
                  placement="top"
                  arrow={{ pointAtCenter: true }}
                >
                  <Dropdown
                    menu={{ items: scheduleActions, onClick: handleOnClick }}
                    trigger={["click"]}
                    placement="bottomRight"
                    overlayClassName={styles["settings-dropdown"]}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Micons
                        icon="settings"
                        wrapperStyle={{
                          width: "max-content",
                          marginLeft: "auto",
                        }}
                      />
                    </a>
                  </Dropdown>
                </Tooltip>
              </Col>
            </Row>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "10px 0px",
              alignItems: "flex-end",
            }}
          >
            {" "}
            <div>
              <p>Timezone</p>
              <Select
                showSearch
                defaultValue={
                  selectedSchedule.timezone !== "UTC"
                    ? selectedSchedule.timezone
                    : dayjs.tz.guess()
                }
                style={{ width: 200 }}
                options={timeZones.map((el: string) => ({
                  label: el,
                  value: el,
                }))}
                onChange={(val) => {
                  setIsEdit(true);
                  setSubmitData({
                    name: selectedSchedule.name,
                    availabilities: selectedSchedule.availabilities.map(
                      (el) => {
                        return {
                          type: el.type,
                          [`${el.type === "Date" ? "date" : "day"}`]: `${
                            el.type === "Date" ? el.date : el.day
                          }`,
                          availabilityTimes: el.availabilityTimes.map(
                            (item) => ({
                              startTime: item.startTime,
                              endTime: item.endTime,
                            })
                          ),
                        };
                      }
                    ),
                    isCustom: selectedSchedule.isCustom,
                    timezone: val,
                  });
                }}
              />
            </div>
            {isEvent && (
              <Button
                type="link"
                onClick={() => {
                  setIsEdit(false);
                  setIsOpen(true);
                }}
              >
                Save as new schedule
              </Button>
            )}
            <Segmented
              size="large"
              options={[
                {
                  label: "List View",
                  icon: <Micons icon="list" />,
                  value: "list",
                },
                {
                  label: "Calendar View",
                  icon: <Micons icon="calendar_today" />,
                  value: "calendar",
                },
              ]}
              value={view}
              onChange={(value) => changeView(value)}
            />
          </div>
        </div>
        {view === "list" ? (
          <Row>
            <Col sm={16}>
              <SetWeeklyHours
                selectedSchedule={selectedSchedule}
                setIsEdit={setIsEdit}
                setIsSubmitting={setIsSubmitting}
                handleSubmit={(val: any) => {
                  handleFormSubmit(val, false);
                }}
                isSubmitting={isSubmitting}
              />
            </Col>
            <Col sm={8}>
              <DateOverride
                selectedSchedule={selectedSchedule}
                setSelectedSchedule={setSelectedSchedule}
                setIsEdit={setIsEdit}
                handleSubmit={(val: any) => {
                  handleFormSubmit(val, false);
                }}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />
            </Col>
          </Row>
        ) : (
          // <CalendarView selectedSchedule={selectedSchedule} />
          <></>
        )}
      </Wrapper>
    </>
  ) : (
    <></>
  );
};

export default Schedule;
