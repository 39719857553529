/**
 * Returns whether a value is a string.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isString(val: any) {
  return typeof val === "string";
}

/**
 * Returns whether a value is an array.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isArray(val: any) {
  return Object.prototype.toString.call(val) === "[object Array]";
}

/**
 * Returns whether a value is not empty array.
 *
 * @param  {*} val
 * @return {Boolean}
 */
export function isNotEmptyArray(val: any) {
  return isArray(val) && val.length !== 0;
}
