import { useState, useEffect } from "react";
import {
  DatePicker,
  Tabs,
  Radio,
  message,
  Collapse,
  Empty,
  Row,
  Col,
} from "antd";
import axios from "axios";
import dayjs, { type Dayjs } from "dayjs";

import Wrapper from "../../../components/wrapper";
import Micons from "../../../components/customAntd/micons";
import { getApiErrorMsg } from "../../../utils/object-util";
import Spinner from "../../../components/spinner";

import styles from "./scheduledEvents.module.scss";

const { RangePicker } = DatePicker;

const ScheduledEvents = () => {
  const [bookings, setAllBookings] = useState<any>();
  const [filter, setFilter] = useState<string>("upcoming");
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState<Dayjs[]>([
    dayjs().startOf("day"),
    dayjs().endOf("day"),
  ]);

  const fetchData = async () => {
    if (filter === "range" && range === null) return;
    try {
      const filterObject = {
        limit: 20,
        offset: 0,
        orderBy: { slotTime: "asc" },
        filterCondition: {
          ...(["upcoming", "range"].includes(filter) && {
            startDate:
              filter === "upcoming"
                ? dayjs().format()
                : range[0].startOf("day").format(),
          }),
          ...(["past", "range"].includes(filter) && {
            endDate:
              filter === "past"
                ? dayjs().format()
                : range[1].endOf("day").format(),
          }),
        },
      };
      setLoading(true);

      const response = await axios.post("bookings/all", filterObject);

      if (response.data?.data) {
        const groupedBookings: any = [];
        let i = 0;
        response.data.data.forEach((el: any) => {
          const check = groupedBookings.find((innerEl: any, index: number) => {
            i = index;

            return (
              dayjs(innerEl.slotTime).format("DD-MM-YYYY") ===
              dayjs(el.slotTime).format("DD-MM-YYYY")
            );
          });
          if (check) {
            groupedBookings[i].bookings.push(el);
          } else {
            groupedBookings.push({ slotTime: el.slotTime, bookings: [el] });
          }
          i = 0;
        });
        setLoading(false);
        setAllBookings(groupedBookings);
      }
    } catch (err) {
      message.error({
        content: getApiErrorMsg(err),
        key: "error",
        duration: 2,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [filter, range]);

  const handleTabChange = (key: string) => {
    if (key === "range") {
      setFilter("range");
    } else {
      setFilter(key);
    }
  };

  const renderFooter = () => (
    <div className={styles["popover-footer"]}>
      <div className={styles["footer-buttons"]}>
        <Radio.Group
          options={[
            { label: "Today", value: "day" },
            { label: "This Week", value: "week" },
            { label: "This Month", value: "month" },
          ]}
          optionType="button"
          onChange={(e) => {
            setRange([
              dayjs().startOf(e.target.value),
              dayjs().endOf(e.target.value),
            ]);
          }}
        />
      </div>

      {/* <Button
        className={styles["cancel-button"]}
        onClick={() => {
          setIsDatePickerOpen(false);
          setFilter("past");
        }}
      >
        Cancel
      </Button>
      <Button type="primary">Apply</Button> */}
    </div>
  );

  const generateCollapseItem = (booking: any) => {
    const startSlotTime = dayjs(booking.slotTime);
    const endSlotTime = startSlotTime.add(
      booking.event.duration,
      booking.event.durationUnit
    );
    let meetURL = "";
    if (booking?.meeting?.locationType === "GoogleMeet") {
      meetURL = booking.meeting.googleMeetUrl;
    } else if (booking?.meeting?.locationType === "Zoom") {
      meetURL = booking.meeting.zoomUrl;
    } else {
      meetURL = booking?.meeting?.location;
    }

    return {
      key: booking.id,
      label: (
        <Row className={styles["collapse-header"]}>
          <Col sm={11}>
            <div className={styles["profile-container"]}>
              <div
                className={styles["profile-color"]}
                style={{ backgroundColor: `${booking.event.color}` }}
              ></div>
              <p className={styles["collapse-header-time"]}>
                {`${startSlotTime.format("h:mm")} - ${endSlotTime.format(
                  "h:mm A"
                )}`}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div>
              <p className={styles["collapse-header-name"]}>
                {booking.customerName}
              </p>
              <p className={styles["collapse-event-type"]}>
                Event type
                <span className={styles["collapse-event-name"]}>
                  {booking.event.name}
                </span>
              </p>
            </div>
          </Col>
          <Col sm={1}>
            <div className={styles["collapse-details"]}>Details</div>
          </Col>
        </Row>
      ),
      children: (
        <Row>
          <Col sm={13} offset={11}>
            <div
              className={`${styles["collapse-panel-content"]} ${styles["center-column"]}`}
            >
              <div className={styles["content-section"]}>
                <div className={styles["email-container"]}>
                  <p className={styles["section-email"]}>EMAIL</p>
                  <p className={styles["customer-email"]}>
                    {booking.customerEmail}
                  </p>
                </div>
                <div className={styles["location-container"]}>
                  <p className={styles["section-email"]}>LOCATION</p>
                  <p>{booking.event.locationType}</p>
                  {booking.event.locationType === "InPerson" ? (
                    <>
                      <p>
                        Address:
                        <span style={{ marginLeft: 5 }}>{meetURL}</span>
                      </p>
                    </>
                  ) : (
                    <a href={meetURL} rel="noreferrer" target="_blank">
                      {meetURL}
                    </a>
                  )}
                </div>
                <div className={styles["location-container"]}>
                  <p className={styles["section-email"]}>INVITEE TIME ZONE</p>
                  <p>{booking.timezone}</p>
                </div>

                <div className={styles["questions-container"]}>
                  <p className={`${styles["section-email"]}`}>QUESTIONS</p>
                  {booking.event.questions.map((question: any) => (
                    <div
                      className={styles["panel-questions"]}
                      key={question.id}
                    >
                      <p className={styles["interview-questions"]}>
                        {question.question}:
                      </p>
                      <p>{booking.inviteeResponse[question.id]}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      ),
    };
  };

  return (
    <Wrapper className={styles["scheduled-events-wrapper"]}>
      <Tabs
        items={[
          {
            key: "upcoming",
            label: "Upcoming",
          },
          {
            key: "past",
            label: "Past",
          },
          {
            key: "range",
            label: (
              <>
                <div className={styles["date-range"]}>
                  Date Range <Micons icon="arrow_drop_down" />
                </div>
                <RangePicker
                  style={{
                    position: "absolute",
                    opacity: 0,
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  renderExtraFooter={renderFooter}
                  value={range as any}
                  onChange={(newRange) => {
                    setRange(newRange as any);
                    // setIsDatePickerOpen(false);
                  }}
                />
              </>
            ),
          },
        ]}
        onTabClick={handleTabChange}
      />

      {loading ? (
        <div style={{ margin: 100 }}>
          <Spinner />
        </div>
      ) : (
        <>
          {filter === "range" && range && (
            <div className={styles["day-range"]}>
              {range[0]?.format("D MMM")} – {range[1]?.format("D MMM YYYY")}
            </div>
          )}
          {bookings?.length > 0 ? (
            bookings?.map((slot: any) => {
              return (
                <>
                  <div key={slot.slotTime} className={styles["day-header"]}>
                    {dayjs(slot.slotTime).format("dddd, D MMMM YYYY")}
                  </div>
                  <Collapse
                    expandIconPosition="end"
                    ghost
                    accordion
                    className={styles["wrapper"]}
                    items={slot?.bookings?.map((booking: any) =>
                      generateCollapseItem(booking)
                    )}
                  />
                </>
              );
            })
          ) : (
            <Empty
              description="No Scheduled Events Found"
              style={{ marginTop: 100, marginBottom: 50 }}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default ScheduledEvents;
