import { useEffect, useState } from "react";

// import { Form, Button, message, Divider } from "antd";
import { Button, message } from "antd";
import axios from "axios";
// import { Link } from "react-router-dom";

// import { Input } from "../../components/form-fields/input";
// import { useAppContext } from "../../components/contexts/appContext";
// import { ILoginData } from "../../interfaces/user";
// import { loginTokenKey } from "../../constants";
import AuthLayout from "../../components/layouts/auth-layout";
import { getApiErrorMsg } from "../../utils/object-util";
import GoogleIcon from "../../assets/images/google.svg";

import styles from "./login.module.scss";

// const { Item } = Form;

const Login = () => {
  const [url, setUrl] = useState<string>("");
  // const { loadCurrentUser } = useAppContext();

  useEffect(() => {
    axios
      .get("google/auth-url")
      .then((resp) => {
        if (resp.data.url) {
          setUrl(resp.data.url);
        }
      })
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "google",
          duration: 2,
        });
      });
  }, []);

  // const onSubmit = (data: ILoginData) => {
  //   axios
  //     .post(`users/login`, data)
  //     .then((response) => {
  //       localStorage.setItem(loginTokenKey, response?.data?.data?.token);
  //       loadCurrentUser();
  //     })
  //     .catch((err) => {
  //       message.error({
  //         content: getApiErrorMsg(err),
  //         key: "login",
  //         duration: 2,
  //       });
  //     });
  // };

  const handleGoogleLogin = () => {
    window.open(url, "_self");
  };

  return (
    <AuthLayout>
      {/* <p style={{ marginBottom: 10 }}>Please login to continue</p>
      <Form
        name="login-form"
        labelCol={{ span: 24 }}
        onFinish={onSubmit}
        autoComplete="off"
        className={styles["login-form"]}
      >
        <Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email!" },
            { type: "email", message: "Please enter a valid email!" },
          ]}
        >
          <Input type="email" />
        </Item>
        <Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please enter your password!" }]}
        >
          <Input type="password" />
        </Item>
        <Button
          htmlType="submit"
          className={styles["login-button"]}
          type="primary"
        >
          Login
        </Button>
        <Divider orientation="center"> OR </Divider>
        <Button
          icon={<img src={GoogleIcon} />}
          onClick={handleGoogleLogin}
          className={styles["google-button"]}
        >
          Sign in With Google
        </Button>
        <Link to="/signup" className={styles["sign-up-link"]}>
          Sign Up
          <Micons icon="arrow_forward" type="filled" />
        </Link>
      </Form> */}
      <h1>Welcome to the Calendar App</h1>
      <p style={{ margin: "20px 0px", color: "#1a1a1a9c" }}>
        Streamline your scheduling with seamless integrations for Google
        Calendar and Google Meet, alongside Zoom and Stripe.{" "}
      </p>
      <Button
        icon={<img src={GoogleIcon} />}
        onClick={handleGoogleLogin}
        className={styles["google-button"]}
      >
        Log In / Sign Up with Google
      </Button>
    </AuthLayout>
  );
};

export default Login;
