import { Layout, Menu, Avatar, Tooltip } from "antd";
import { Link } from "react-router-dom";

import Micons from "../../customAntd/micons";
import { useAppContext } from "../../contexts/appContext";

import styles from "./privateLayout.module.scss";

interface PrivateLayoutProps {
  children: React.ReactNode;
  handleLogout: () => void;
}

const { Sider } = Layout;

const PrivateLayout = ({ children, handleLogout }: PrivateLayoutProps) => {
  const { user } = useAppContext();
  const menuItems = [
    {
      label: "Events",
      icon: <Micons icon="event" />,
      key: "events",
      link: "/events",
    },
    {
      label: "Availability",
      icon: <Micons icon="event_available" />,
      key: "availability",
      link: "/availability",
    },
    {
      label: "Payout",
      icon: <Micons icon="payments" />,
      key: "payout",
      link: "/payout",
    },
    {
      label: "Profile",
      icon: <Micons icon="person" />,
      key: "profile",
      link: "/profile",
    },
    {
      label: "Integrations",
      icon: <Micons icon="integration_instructions" />,
      key: "integration",
      link: "/integration",
    },
    {
      label: "Logout",
      icon: <Micons icon="logout" />,
      key: "logout",
    },
  ];

  return (
    <Layout style={{ minHeight: "100vh" }} hasSider>
      <Sider className={styles.sider}>
        <div className={styles["user-details-container"]}>
          <Avatar
            size={40}
            style={{ backgroundColor: user?.rgbIcon, fontWeight: "bold" }}
          >
            {user?.firstName[0].toUpperCase() || ""}
          </Avatar>
          <div style={{ textAlign: "center" }}>
            <p
              className={styles["user-name"]}
            >{`${user?.firstName} ${user?.lastName}`}</p>
            <Tooltip
              title="Click here to go to events booking page"
              placement="right"
            >
              <a
                href={`${window.location.origin}/${user?.link}`}
                target="_blank"
                rel="noreferrer"
              >
                Events listing page
              </a>
            </Tooltip>
          </div>
        </div>
        <Menu
          theme="dark"
          defaultSelectedKeys={["events"]}
          mode="inline"
          items={menuItems.map((el) => ({
            ...el,
            label: el.link ? <Link to={el.link}>{el.label}</Link> : el.label,
          }))}
          selectedKeys={[window.location.pathname.split("/")[1]]}
          onClick={(info) => {
            if (info.key === "logout") {
              handleLogout();
            }
          }}
          className={styles.menu}
        />
      </Sider>
      <Layout className={styles["content-wrapper"]}>{children}</Layout>
    </Layout>
  );
};

export default PrivateLayout;
