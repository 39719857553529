import { useState, useEffect } from "react";
import { Row, Col, message } from "antd";
import axios from "axios";

import Micons from "../../components/customAntd/micons";
import { useAppContext } from "../../components/contexts/appContext";
import { getApiErrorMsg } from "../../utils/object-util";
import GoogleCalendarIcon from "../../assets/images/google-calendar.png";
import ZoomIcon from "../../assets/images/zoom.png";
import StripeIcon from "../../assets/images/stripe.svg";
import Spinner from "../../components/spinner";

import styles from "./integration.module.scss";

interface CardElement {
  key: string;
  imgSrc: string;
  label: string;
  description: string;
  link: string;
  isIntegrated: boolean;
}

const Integration = () => {
  const { user, loadCurrentUser, loadingUser } = useAppContext();
  const [menu, setMenu] = useState<CardElement[]>([]);

  useEffect(() => {
    loadCurrentUser(true);
  }, []);

  useEffect(() => {
    setMenu([
      {
        label: "Google Calendar & Meet",
        imgSrc: GoogleCalendarIcon,
        key: "google",
        link: "google-calendar",
        isIntegrated: !!user?.googleRefreshToken,
        description:
          "Integrate your Google Calendar and Google Meet. Schedule and manage your events seamlessly.",
      },
      {
        label: "Zoom",
        imgSrc: ZoomIcon,
        key: "zoom",
        link: "zoom",
        isIntegrated: !!user?.zoomRefreshToken,
        description: "Include zoom details in your calendly events.",
      },
      {
        label: "Stripe",
        imgSrc: StripeIcon,
        key: "stripe",
        link: "stripe",
        isIntegrated: !!user?.stripeId,
        description: "Link your stripe account for payouts.",
      },
    ]);
  }, [user]);

  return (
    <>
      <h2 style={{ marginBottom: 15 }}>All Integrations</h2>
      <p style={{ marginBottom: 30, fontSize: 16 }}>
        Click on the cards below for integrating the required services.
      </p>
      {loadingUser ? (
        <Spinner />
      ) : (
        <Row gutter={[30, 30]}>
          {menu.map((menuItem) => {
            return (
              <Col key={menuItem.key} sm={12} md={8} lg={12}>
                <Card data={menuItem} />
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

const Card = ({ data }: { data: CardElement }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    if (data.isIntegrated || loading) {
      //Disable the div if its verified
      return;
    }
    setLoading(true);
    try {
      const url = `${data.link}/auth-url`; // Default URL
      const response = await axios.get(url);
      window.open(response.data.url, "_self");
    } catch (error) {
      message.error({
        content: getApiErrorMsg(error),
        key: "error",
        duration: 2,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`${styles["card-cont"]} ${
        data.isIntegrated ? styles["integrated"] : ""
      }`}
      onClick={handleClick}
    >
      <div className={styles["icons"]}>
        <img width={40} height={40} src={data.imgSrc} alt={data.label} />
        {data.isIntegrated && (
          <Micons
            icon="verified_user"
            style={{ color: "green", fontSize: 35 }}
          />
        )}
      </div>
      <p className={styles["title"]}>{data.label}</p>
      <p className={styles["desc"]}>{data.description}</p>
    </div>
  );
};

export default Integration;
