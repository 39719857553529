export const debounceMethod = (func: any) => {
  let timer: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), 500);
  };
};

export const convertCurrency = async (
  from: string,
  to: string,
  amount: number
) => {
  if (from === to) {
    return amount;
  }
  //Using fetch here because a third party url is being used.
  try {
    const response = await fetch(
      `${process.env.REACT_APP_OPEN_EXCHANGE_RATES_URL}?app_id="${process.env.REACT_APP_OPEN_EXCHANGE_RATES_APP_ID}`
    );
    const data = await response.json();
    const { base, rates } = data;
    if (to === base) {
      return amount / rates[from];
    } else {
      //convert amount to base and then convert base to required currency
      return (amount / rates[from]) * rates[to];
    }
  } catch (err) {
    return "Something went wrong. Please try again later.";
  }
};
