import { Tabs } from "antd";

import Schedule from "./schedule";

const Availability = () => {
  return (
    <>
      <h2>Availability</h2>
      <Tabs
        defaultActiveKey="1"
        tabPosition="top"
        items={[
          {
            label: "Hours and Preferences",
            key: "1",
            children: <Schedule />,
          },
          {
            label: "Connected Calendars",
            key: "2",
            children: <p>Content 2</p>,
          },
        ]}
      />
    </>
  );
};

export default Availability;
