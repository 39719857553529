import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./App";

import "./index.css";
import "./styles/index.scss";
import "./axios/interceptor";
import "material-icons/iconfont/material-icons.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Router>
    <App />
  </Router>
);
