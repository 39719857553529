import { useState, useEffect } from "react";
import { Button, Form, Row, Col } from "antd";
import dayjs from "dayjs";

import Micons from "../../../../components/customAntd/micons";
import { IAvailabilities, ISchedule } from "../index";
import DateOverrideCalendar from "../dateOverrideCalendar";

import styles from "./dateOverride.module.scss";

interface IDateOverridesProps {
  selectedSchedule: ISchedule;
  setSelectedSchedule: (arg: ISchedule) => void;
  onDelete?: () => void;
  setCustomSchedule?: (arg: ISchedule) => void;
  handleSubmit: (data: any) => void;
  setIsEdit: (arg: boolean) => void;
  isSubmitting: boolean;
  setIsSubmitting: (arg: boolean) => void;
}

const DateOverride = ({
  selectedSchedule,
  setIsEdit,
  handleSubmit,
  isSubmitting,
  setIsSubmitting,
}: IDateOverridesProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dateOverrides, setDateOverrides] = useState<IAvailabilities[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>();
  const [submitData, setSubmitData] = useState<any>(null);
  const [form] = Form.useForm();

  const handleClose = () => {
    setIsOpen(false);
    setSelectedDate("");
  };

  useEffect(() => {
    if (submitData) {
      handleSubmit(submitData);
      setSubmitData(null);
    }
  }, [submitData]);

  useEffect(() => {
    setDateOverrides(
      selectedSchedule?.availabilities?.filter(
        (item) => item.type === "Date"
      ) || []
    );
  }, [selectedSchedule]);

  const handleDelete = (index: number) => {
    const tempArr: any = [];
    if (selectedSchedule.availabilities) {
      selectedSchedule.availabilities.forEach((el, elIndex) => {
        if (elIndex !== index) {
          const typeKey = el.type === "Date" ? "date" : "day";
          tempArr.push({
            [typeKey]: el[typeKey],
            type: el.type,
            availabilityTimes: el.availabilityTimes.map((el) => ({
              startTime: el.startTime,
              endTime: el.endTime,
            })),
          });
        }
      });
    }
    const updatedSchedule = {
      ...selectedSchedule,
      availabilities: tempArr,
    };
    setIsSubmitting(true);
    setIsEdit(true);
    setSubmitData(updatedSchedule);
  };

  return (
    <>
      <DateOverrideCalendar
        isOpen={isOpen}
        onCancel={handleClose}
        title="Select the date(s) you want to assign specific hours"
        selectedSchedule={selectedSchedule}
        form={form}
        setIsOpen={setIsOpen}
        dateOverrides={dateOverrides}
        selectedDate={selectedDate}
        setIsEdit={setIsEdit}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        handleSubmit={handleSubmit}
      />
      <div className={styles.wrapper}>
        <h3>Add date overrides</h3>
        {dateOverrides.length === 0 && (
          <p>Add dates when your availability changes from your weekly hours</p>
        )}
        <Button
          className={styles["add-button"]}
          onClick={() => setIsOpen(true)}
        >
          Add a date override
        </Button>
      </div>
      {dateOverrides.length > 0 && (
        <div className={styles["date-override-list"]}>
          {selectedSchedule?.availabilities.map((el, index) => {
            const { date, id } = el;

            if (el.type !== "Date") {
              return;
            }

            return (
              <Row
                key={id}
                align="top"
                onClick={() => {
                  setSelectedDate(date);
                }}
              >
                <Col sm={8}>
                  <strong>{dayjs(date).format("DD MMM YYYY")}</strong>
                </Col>
                <Col sm={13}>
                  {el.availabilityTimes.length > 0 ? (
                    el.availabilityTimes.map(({ startTime, endTime, id }) => {
                      return (
                        <p key={id}>
                          <span>{dayjs(startTime).format("hh:mm a")}</span> -{" "}
                          <span>{dayjs(endTime).format("hh:mm a")}</span>
                        </p>
                      );
                    })
                  ) : (
                    <p>Unavailable</p>
                  )}
                </Col>
                <Col sm={3}>
                  <Button
                    icon={<Micons icon="delete" />}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDelete(index);
                    }}
                  ></Button>
                </Col>
              </Row>
            );
          })}
        </div>
      )}
    </>
  );
};
export default DateOverride;
