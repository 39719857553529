import { useEffect, useState } from "react";
import { Form, Modal } from "antd";

import { Input } from "../../../../components/form-fields/input";
import { ISchedule } from "../index";

interface IScheduleProps {
  isOpen: boolean;
  isEdit: boolean;
  selectedSchedule: ISchedule;
  onSubmit: (value: any) => void;
  isSubmitting: boolean;
  setIsSubmitting: (value: boolean) => void;
  setSaveNewSchedule?: (value: boolean) => void;
  onClose: () => void;
  isEvent?: boolean;
}

const { Item } = Form;

const ScheduleForm = ({
  isOpen,
  selectedSchedule,
  isEdit,
  onSubmit,
  isSubmitting,
  setIsSubmitting,
  onClose,
  isEvent,
}: IScheduleProps) => {
  const [form] = Form.useForm();
  const [submitData, setSubmitData] = useState<boolean>(false);

  useEffect(() => {
    if (submitData) {
      form.submit();
      setSubmitData(false);
    }
  }, [submitData]);

  useEffect(() => {
    if (isEdit && selectedSchedule && !isEvent) {
      form.setFieldValue("name", selectedSchedule.name);
    }
  }, [isEdit, selectedSchedule]);

  const handleOk = () => {
    setIsSubmitting(true);
    setSubmitData(true);
  };

  return (
    <Modal
      open={isOpen}
      title={
        isEvent
          ? "Want to save this as a new schedule?"
          : isEdit
          ? "Edit Schedule"
          : "Create Schedule"
      }
      okText={`${isEvent ? "Save" : isEdit ? "Update" : "Create"}`}
      cancelText="Cancel"
      onCancel={onClose}
      onOk={handleOk}
      destroyOnClose
      okButtonProps={{ disabled: isSubmitting }}
      cancelButtonProps={{ disabled: isSubmitting }}
      closeIcon={null}
    >
      {isEvent && (
        <p style={{ margin: "20px 0px", fontSize: 16, color: "#1a1a1a" }}>
          Save these hours to reuse with other event types. Manage all your
          schedules under availability.
        </p>
      )}
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        preserve={false}
        onFinish={onSubmit}
        disabled={isSubmitting}
      >
        <Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please enter name of the schedule",
            },
          ]}
        >
          <Input placeholder="Working Hours, Exclusive hours, etc." />
        </Item>
      </Form>
    </Modal>
  );
};

export default ScheduleForm;
