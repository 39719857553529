import { useEffect, useState, useRef } from "react";
import { Form, Popover, Button, TimePicker, Row, Col } from "antd";
import dayjs from "dayjs";

import { Checkbox } from "../../../../components/form-fields/checkbox";
import { days } from "../../../../constants";
import Micons from "../../../../components/customAntd/micons";
import { ISchedule, IAvailabilities } from "../index";

import styles from "./setWeeklyHours.module.scss";

interface ISetWeeklyHoursProps {
  selectedSchedule: ISchedule;
  setCustomSchedule?: (arg: ISchedule) => void;
  setIsEdit: (arg: boolean) => void;
  handleSubmit: (data: any) => void;
  setIsSubmitting: (arg: boolean) => void;
  isSubmitting: boolean;
}

const SetWeeklyHours = ({
  selectedSchedule,
  setIsEdit,
  handleSubmit,
  setIsSubmitting,
}: ISetWeeklyHoursProps) => {
  const [form] = Form.useForm();
  const [checkedDays, setCheckedDays] = useState<string[]>([]);
  const [currentDay, setCurrentDay] = useState<string>("");
  const [open, setOpen] = useState(false); // For slots clone dropdown
  const [submitData, setSubmitData] = useState<any>(null);
  const slotsRightSideRef = useRef<HTMLDivElement>(null);

  const sortDays = (arr: IAvailabilities[]) => {
    const availabilities: any = days.map((el) => {
      const find = arr?.find(
        (availability: IAvailabilities) => availability.day === el.day
      );
      return find;
    });
    return availabilities;
  };

  useEffect(() => {
    if (submitData) {
      handleSubmit(submitData);
      setSubmitData(null);
    }
  }, [submitData]);

  useEffect(() => {
    const weekDayAvailabilities = selectedSchedule?.availabilities?.filter(
      (el) => el.type === "WeekDay"
    );
    const availabilities = sortDays(weekDayAvailabilities).map(
      (item: IAvailabilities) => {
        return {
          ...item,
          available: item.availabilityTimes.length > 0,
          availabilityTimes: item.availabilityTimes.map((time) => {
            const startTimeHour = dayjs(time.startTime).format("HH");
            const startTimeMin = dayjs(time.startTime).format("mm");
            const endTimeHour = dayjs(time.endTime).format("HH");
            const endTimeMin = dayjs(time.endTime).format("mm");
            return {
              ...time,
              startTime: dayjs()
                .set("hour", Number(startTimeHour))
                .set("minute", Number(startTimeMin)),
              endTime: dayjs()
                .set("hour", Number(endTimeHour))
                .set("minute", Number(endTimeMin)),
            };
          }),
        };
      }
    );

    form.setFieldsValue({ availabilities });
  }, [selectedSchedule]);

  const onSubmit = (data: any) => {
    const schedule = {
      ...selectedSchedule,
      availabilities: selectedSchedule?.availabilities?.map((el) => {
        if (el.type === "WeekDay") {
          const formAvailabilityData = data.availabilities.find(
            (item: any) => item.day === el.day
          );
          if (formAvailabilityData) {
            return {
              type: formAvailabilityData.type,
              day: formAvailabilityData.day,
              availabilityTimes: formAvailabilityData?.available
                ? formAvailabilityData.availabilityTimes.map((el: any) => {
                    return {
                      startTime: dayjs(el.startTime).format(),
                      endTime: dayjs(el.endTime).format(),
                    };
                  })
                : [],
            };
          }
        } else {
          return {
            type: el.type,
            date: dayjs(el.date).toISOString(),
            availabilityTimes: el?.availabilityTimes.map((el: any) => ({
              startTime: dayjs(el.startTime).format(),
              endTime: dayjs(el.endTime).format(),
            })),
          };
        }
      }),
    };
    setIsSubmitting(true);
    setIsEdit(true);
    setSubmitData(schedule);
  };

  const onValuesChange = (changedValues: any) => {
    const { availabilities } = changedValues;
    const changedIndex = availabilities.length - 1;
    const [key, value] = Object.entries(
      availabilities[availabilities.length - 1]
    )[0];

    if (key === "available") {
      if (value) {
        form.setFieldValue(
          ["availabilities", changedIndex, "availabilityTimes"],
          [
            {
              startTime: dayjs().set("hour", 9).set("minute", 0),
              endTime: dayjs().set("hour", 10).set("minute", 0),
            },
          ]
        );
        form.setFieldValue(["availabilities", changedIndex, "available"], true);
      } else {
        form.setFieldValue(
          ["availabilities", changedIndex, "availabilityTimes"],
          []
        );
        form.setFieldValue(
          ["availabilities", changedIndex, "available"],
          false
        );
      }
    }
    setTimeout(() => form.submit());
  };

  const onCheckChange = (isChecked: boolean, elDay: string) => {
    if (isChecked) {
      setCheckedDays((prev) => [...prev, elDay]);
    } else {
      setCheckedDays((prev) => prev.filter((el) => el !== elDay));
    }
  };

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
    if (!newOpen) {
      setCurrentDay("");
      setCheckedDays([]);
    }
  };

  const content = (day: string) => {
    return (
      <>
        {days.map((item) => (
          <div className={styles["slot-popover-days"]} key={item.id}>
            <p style={{ width: "50%" }}>{item.day}</p>
            <div>
              <Checkbox
                checked={item.day === day || checkedDays.includes(item.day)}
                disabled={item.day === day}
                onChange={(e) => {
                  onCheckChange(e.target.checked, item.day);
                }}
              />
            </div>
          </div>
        ))}
        <div className={styles["slot-popover-btn"]}>
          <Button
            type={"primary"}
            onClick={() => {
              const dayIndex = selectedSchedule.availabilities.findIndex(
                (availability) => day === availability.day
              );
              const clonableValues = form.getFieldValue([
                "availabilities",
                dayIndex,
                "availabilityTimes",
              ]);
              checkedDays.forEach((el) => {
                const checkedDayIndex =
                  selectedSchedule.availabilities.findIndex(
                    (availability) => el === availability.day
                  );
                if (checkedDayIndex > -1) {
                  form.setFieldValue(
                    ["availabilities", checkedDayIndex, "availabilityTimes"],
                    clonableValues
                  );
                }

                form.setFieldValue(
                  ["availabilities", checkedDayIndex, "available"],
                  !!clonableValues.length
                );
                form.setFieldValue(
                  ["availabilities", checkedDayIndex, "isUnavailable"],
                  !clonableValues.length
                );
              });

              form
                .validateFields()
                .then(() => {
                  form.submit();
                })
                .catch((err) => {
                  if (err.errorFields.length === 0) {
                    form.submit();
                  }
                });

              setCurrentDay("");
              hide();
              setCheckedDays([]);
            }}
          >
            Apply
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <h3>Set your weekly hours</h3>

      <Form form={form} onValuesChange={onValuesChange} onFinish={onSubmit}>
        <Form.List name="availabilities">
          {(fields) => {
            return (
              <>
                {fields.map((field) => {
                  return (
                    <Row
                      gutter={[10, 10]}
                      key={field.key}
                      className={styles["slot-weekdays"]}
                    >
                      <Form.Item name={[field.name, "id"]} hidden />
                      <Col sm={4}>
                        <Form.Item
                          name={[field.name, "available"]}
                          valuePropName="checked"
                          style={{ width: "max-content", display: "inline" }}
                        >
                          <Checkbox
                            title={form
                              .getFieldValue(["availabilities", field.name])
                              .day.slice(0, 3)}
                            // checked={availability.availabilityTimes?.length > 0}
                            // onChange={(e) =>
                            //   updateAvailability(e.target.checked, field.name)
                            // }
                          />
                        </Form.Item>
                      </Col>
                      {!form.getFieldValue([
                        "availabilities",
                        field.name,
                        "available",
                      ]) ? (
                        <Col
                          sm={18}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <p style={{ color: "rgb(26,26,26)" }}>Unavailable</p>
                        </Col>
                      ) : (
                        <Col sm={18}>
                          <Form.List name={[field.name, "availabilityTimes"]}>
                            {(innerFields, { add, remove }) => {
                              return (
                                <Row>
                                  <Col sm={20}>
                                    {innerFields.map((innerField, key) => {
                                      return (
                                        <div
                                          key={key}
                                          className={styles["slots"]}
                                        >
                                          <Form.Item
                                            name={[innerField.name, "id"]}
                                            hidden
                                          />
                                          <Form.Item
                                            name={[
                                              innerField.name,
                                              "startTime",
                                            ]}
                                            dependencies={[
                                              [
                                                "availabilities",
                                                field.name,
                                                "availabilityTimes",
                                                innerField.name,
                                                "endTime",
                                              ],
                                            ]}
                                            rules={[
                                              () => ({
                                                validator(_, value) {
                                                  const endTime =
                                                    form.getFieldValue([
                                                      "availabilities",
                                                      field.name,
                                                      "availabilityTimes",
                                                      innerField.name,
                                                      "endTime",
                                                    ]);
                                                  if (
                                                    value &&
                                                    endTime &&
                                                    dayjs(value) >=
                                                      dayjs(endTime)
                                                    // value.diff(endTime) > 0
                                                  ) {
                                                    return Promise.reject(
                                                      new Error(
                                                        "Invalid time combination"
                                                      )
                                                    );
                                                  }
                                                  return Promise.resolve();
                                                },
                                              }),
                                            ]}
                                          >
                                            <TimePicker
                                              suffixIcon={null}
                                              format="hh:mm A"
                                              minuteStep={15}
                                              showNow={false}
                                              allowClear={false}
                                              style={{ width: "160px" }}
                                            />
                                          </Form.Item>
                                          <span style={{ margin: 5 }}>-</span>

                                          <Form.Item
                                            style={{
                                              marginRight: 10,
                                            }}
                                            name={[innerField.name, "endTime"]}
                                            dependencies={[
                                              [
                                                "availabilities",
                                                field.name,
                                                "availabilityTimes",
                                                innerField.name,
                                                "startTime",
                                              ],
                                            ]}
                                          >
                                            <TimePicker
                                              suffixIcon={null}
                                              format="hh:mm A"
                                              minuteStep={15}
                                              showNow={false}
                                              allowClear={false}
                                              style={{ width: "160px" }}
                                            />
                                          </Form.Item>
                                          <Button
                                            onClick={() => {
                                              remove(innerField.name);
                                              if (innerFields.length === 1) {
                                                form.setFieldValue(
                                                  [
                                                    "availabilities",
                                                    field.name,
                                                    "isUnavailable",
                                                  ],
                                                  true
                                                );
                                                form.setFieldValue(
                                                  [
                                                    "availabilities",
                                                    field.name,
                                                    "available",
                                                  ],
                                                  false
                                                );
                                              }
                                            }}
                                          >
                                            <Micons icon="delete" />
                                          </Button>
                                        </div>
                                      );
                                    })}
                                  </Col>
                                  <Col sm={4}>
                                    <Button
                                      onClick={() => {
                                        const endTime = form.getFieldValue([
                                          "availabilities",
                                          field.name,
                                          "availabilityTimes",
                                          innerFields.length - 1,
                                          "endTime",
                                        ]);
                                        const date = dayjs(endTime).get("date");
                                        const month =
                                          dayjs(endTime).get("month");
                                        const year = dayjs(endTime).get("year");
                                        add({
                                          startTime: dayjs(endTime),
                                          endTime: dayjs(endTime)
                                            .add(1, "hour")
                                            .set("year", year)
                                            .set("month", month)
                                            .set("date", date),
                                        });
                                      }}
                                    >
                                      <Micons icon="add" />
                                    </Button>
                                  </Col>
                                </Row>
                              );
                            }}
                          </Form.List>
                        </Col>
                      )}
                      <Col sm={2} style={{ marginLeft: -10 }}>
                        <Popover
                          content={content(
                            selectedSchedule.availabilities?.[field.name].day ||
                              ""
                          )}
                          title="Copy times to..."
                          trigger="click"
                          placement="bottomLeft"
                          style={{ background: "black" }}
                          getPopupContainer={() =>
                            slotsRightSideRef.current || document.body
                          }
                          open={
                            currentDay ===
                              selectedSchedule.availabilities?.[field.name]
                                .day && open
                          }
                          onOpenChange={handleOpenChange}
                          arrow={false}
                        >
                          <Button
                            onClick={() =>
                              setCurrentDay(
                                selectedSchedule.availabilities?.[field.name]
                                  .day || ""
                              )
                            }
                          >
                            <Micons icon="copy" />
                          </Button>
                        </Popover>
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          }}
        </Form.List>
      </Form>
    </div>
  );
};

export default SetWeeklyHours;
