import {
  Input as AntdInput,
  InputNumber as AntdInputNumber,
  InputNumberProps,
} from "antd";
import { InputProps } from "antd/lib/input";

import styles from "./input.module.scss";

interface IInputProps extends InputProps {
  className?: string;
  wrapperStyle?: React.CSSProperties;
}

interface IInputNumberProps extends InputNumberProps {
  className?: string;
  wrapperStyle?: React.CSSProperties;
}

export const Input = ({ className, wrapperStyle, ...rest }: IInputProps) => {
  return (
    <div className={styles["wrapper"]} style={wrapperStyle}>
      {rest.type === "password" ? (
        <AntdInput.Password
          className={`${styles["input"]} ${className || ""}`}
          {...rest}
        />
      ) : (
        <AntdInput
          className={`${styles["input"]} ${className || ""}`}
          {...rest}
        />
      )}
    </div>
  );
};

export const InputNumber = (props: IInputNumberProps) => {
  const { className, wrapperStyle, ...rest } = props;

  return (
    <div className={styles["wrapper"]} style={wrapperStyle}>
      <AntdInputNumber
        className={`${styles["input"]} ${className || ""}`}
        {...rest}
      />
    </div>
  );
};
