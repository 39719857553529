import { useEffect, useState } from "react";
import axios from "axios";
import { Form, Select, Row, Col, message, Button, Modal, Spin } from "antd";

import { useAppContext } from "../../components/contexts/appContext";
import { InputNumber } from "../../components/form-fields/input";
import { getApiErrorMsg } from "../../utils/object-util";
import { convertCurrency } from "../../utils/function-util";
import Spinner from "../../components/spinner";

import styles from "./payout.module.scss";

const { Item } = Form;

const Payout = () => {
  const [amount, setAmount] = useState<number>();
  const [isAmountLoading, setIsAmountLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { user, loadCurrentUser, loadingUser } = useAppContext();
  const [form] = Form.useForm();
  const watchCurrency = Form.useWatch("currency", form);

  useEffect(() => {
    loadCurrentUser(true);
  }, []);

  const getConveretedAmount = async () => {
    if (amount) {
      setIsAmountLoading(true);
      const value = await convertCurrency(
        user?.currency,
        watchCurrency,
        amount
      );
      if (typeof value === "string") {
        message.error({
          content: amount,
          key: "error",
          duration: 2,
        });
      } else {
        setAmount(value);
      }
      setIsAmountLoading(false);
    }
  };

  useEffect(() => {
    if (watchCurrency !== user?.currency) {
      getConveretedAmount();
    } else {
      setAmount(user?.walletAmount);
    }
    form.setFieldValue("amount", null);
  }, [watchCurrency]);

  const handleSubmit = () => {
    const data = form.getFieldsValue();
    axios
      .post("payouts", data)
      // eslint-disable-next-line no-console
      .then((resp) => console.log(resp))
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "error",
          duration: 2,
        });
      });
  };

  return (
    <>
      <Modal
        okText="Confirm"
        cancelText="Cancel"
        okButtonProps={{ onClick: handleSubmit }}
        cancelButtonProps={{ onClick: () => setIsOpen(false) }}
        destroyOnClose
        centered
        width={380}
        title="Confirmation"
        open={isOpen}
        closeIcon={null}
        className={styles["confirmation-modal"]}
      >
        <p>
          As per our policies, {user?.payoutCommisionPercent}% of the payout
          amount will be deducted as comission.
        </p>
        <p>
          Net payable amount:{" "}
          {watchCurrency === "INR" ? <span>&#8377;</span> : <span>&#36;</span>}{" "}
          {form.getFieldValue("amount") -
            form.getFieldValue("amount") * (user?.payoutCommisionPercent / 100)}
        </p>
      </Modal>
      {loadingUser ? (
        <Spinner />
      ) : (
        <>
          <h1 style={{ marginBottom: 30 }}>
            Payable Amount:{" "}
            {isAmountLoading ? (
              <Spin size="small" style={{ marginLeft: 20 }} />
            ) : (
              <>
                {watchCurrency === "INR" ? (
                  <span>&#8377;</span>
                ) : (
                  <span>&#36;</span>
                )}
                {amount?.toFixed(2)}
              </>
            )}
          </h1>
          <Row>
            <Col sm={8}>
              <Form
                layout="vertical"
                onFinish={() => setIsOpen(true)}
                form={form}
                initialValues={{ currency: "INR", destinationType: "Card" }}
                disabled={isAmountLoading}
              >
                <Item name="currency" label="Currency">
                  <Select
                    options={[
                      { label: "INR", value: "INR" },
                      { label: "USD", value: "USD" },
                    ]}
                  />
                </Item>
                <Item
                  name="amount"
                  label="Amount"
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount",
                    },
                    {
                      validator: (_, val) => {
                        if (amount && val > amount) {
                          return Promise.reject(
                            "Amount must be less than the total payable amount"
                          );
                        } else if (amount && val && Number(val) <= 0) {
                          return Promise.reject("Invalid amount");
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    precision={2}
                    controls={false}
                    prefix={
                      watchCurrency === "INR" ? (
                        <span>&#8377;</span>
                      ) : (
                        <span>&#36;</span>
                      )
                    }
                    className={styles["amount"]}
                  />
                </Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={!user?.stripeId}
                >
                  Submit
                </Button>
              </Form>
              {!user?.stripeId && (
                <>
                  <p style={{ marginTop: 10 }}>Stripe is not integrated.</p>
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                      window.open(user?.stripeAuthUrl, "_self");
                    }}
                  >
                    Click here to link your stripe account and enable payouts.
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default Payout;
