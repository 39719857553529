import { Tabs } from "antd";
import EventTypes from "./eventTypes";
import ScheduledEvents from "./scheduledEvents";

const Events = () => {
  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition="top"
      items={[
        { key: "1", label: "Event Types", children: <EventTypes /> },
        { key: "2", label: "Scheduled Events", children: <ScheduledEvents /> },
      ]}
    />
  );
};

export default Events;
