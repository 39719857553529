import { useEffect, useState } from "react";
import { Button, Calendar } from "antd";
import dayjs, { type Dayjs } from "dayjs";

import PreviousIcon from "../../../assets/images/previous-icon.svg";
import NextIcon from "../../../assets/images/next-icon.svg";
import Spinner from "../../../components/spinner";
import { useAppContext } from "../../../components/contexts/appContext";
import { IEventDetails } from "../../../interfaces/events";

import styles from "./interviewScheduler.module.scss";

export interface IAvailableDates {
  date: string;
  slots: { bookingCount: number; slotTime: string }[];
}
interface InterviewSchedulerProps {
  selectedDate?: string;
  calendarLocalDate: Dayjs;
  setCalendarLocalDate: (args: Dayjs) => void;
  setSelectedDate: (args: string) => void;
  loading: boolean;
  availableDates: IAvailableDates[];
  eventDetail: IEventDetails;
}

const InterviewScheduler = ({
  selectedDate,
  setSelectedDate,
  calendarLocalDate,
  setCalendarLocalDate,
  loading,
  availableDates,
  eventDetail,
}: InterviewSchedulerProps) => {
  const { isAutoscalLandingPage, isProfilePage } = useAppContext();
  const [weekDates, setWeekDates] = useState<any[]>([]);

  dayjs.updateLocale("en", {
    weekdaysMin: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  });

  useEffect(() => {
    const startOfWeek = calendarLocalDate.startOf("week");
    const endOfWeek = calendarLocalDate.endOf("week");
    setWeekDates([startOfWeek, endOfWeek]);
  }, [calendarLocalDate]);

  function checkCanMoveNextMonth(date: Dayjs) {
    if (eventDetail.scheduleType === "Indefinite") {
      return true;
    } else if (eventDetail.scheduleType === "DateRange") {
      return (
        date.endOf("month") < dayjs(eventDetail.endDate).tz().startOf("day")
      );
    } else if (eventDetail.scheduleType === "DaysCount") {
      return (
        date.endOf("month") < dayjs().tz().add(eventDetail.daysCount, "day")
      );
    }
    return false;
  }

  function checkDateAvailability(date: Dayjs) {
    const dateToBeCompared = date.startOf("day");
    const availableDate = availableDates.find(
      (el) =>
        dayjs(el.date).tz().format("DD-MM-YYYY") ===
        dateToBeCompared.format("DD-MM-YYYY")
    );

    return !!availableDate?.slots?.length;
    // }
  }

  const canMovePrevWeek =
    dayjs().tz().startOf("week") < calendarLocalDate.startOf("week");

  const handlePrevWeek = () => {
    const prevWeek = calendarLocalDate.subtract(1, "week");
    setCalendarLocalDate(prevWeek.startOf("week"));
  };

  const handleNextWeek = () => {
    const nextWeek = calendarLocalDate.add(1, "week");
    setCalendarLocalDate(nextWeek.startOf("week"));
  };

  return (
    <div
      className={`${styles["calendar-wrapper"]} ${
        isAutoscalLandingPage && styles["calendar-wrapper-landing"]
      } ${isProfilePage && styles["calendar-wrapper-profile"]}`}
    >
      <p className={styles["title"]}>
        {isAutoscalLandingPage
          ? "Preferred Date:"
          : isProfilePage
          ? "Select Date"
          : "Select Date or time"}
      </p>

      {loading && !isProfilePage ? (
        <Spinner />
      ) : (
        <>
          {isProfilePage ? (
            <div className={styles["with-btn"]}>
              <Button
                type="text"
                onClick={handlePrevWeek}
                className={styles["icon-btn"]}
                disabled={!canMovePrevWeek}
              >
                <img
                  src={PreviousIcon}
                  alt="previous-icon"
                  className={styles["change-icons"]}
                />
              </Button>
              <Calendar
                fullscreen={false}
                mode="month"
                value={calendarLocalDate}
                onSelect={(date: Dayjs) =>
                  setSelectedDate(date.format("DD-MM-YYYY"))
                }
                disabledDate={(date) => !checkDateAvailability(date)}
                headerRender={() => null}
                fullCellRender={(date: Dayjs) => {
                  const slotsExists = checkDateAvailability(date);
                  const isSelected =
                    selectedDate && selectedDate === date.format("DD-MM-YYYY");
                  if (isProfilePage) {
                    if (
                      !(
                        date.isSameOrAfter(weekDates[0]) &&
                        date.isSameOrBefore(weekDates[1])
                      )
                    ) {
                      return null;
                    }
                  }
                  return (
                    <div
                      className={`ant-picker-cell-inner ant-picker-calendar-date ${
                        slotsExists ? styles["has-slots"] : ""
                      } ${isSelected ? styles["custom-selected"] : ""}`}
                    >
                      <div
                        className={`ant-picker-calendar-date-value ${
                          !slotsExists && styles["no-slots"]
                        }`}
                      >
                        {date.format("D")}
                      </div>
                      {isProfilePage && (
                        <span className={styles["month-label"]}>
                          {date.format("MMM")}
                        </span>
                      )}
                    </div>
                  );
                }}
              />
              <Button
                type="text"
                onClick={handleNextWeek}
                className={styles["icon-btn"]}
              >
                <img
                  src={NextIcon}
                  alt="previous-icon"
                  className={styles["change-icons"]}
                />
              </Button>
            </div>
          ) : (
            <Calendar
              fullscreen={false}
              mode="month"
              value={calendarLocalDate}
              onSelect={(date: Dayjs) =>
                setSelectedDate(date.format("DD-MM-YYYY"))
              }
              disabledDate={(date) =>
                !checkDateAvailability(date) ||
                date.format("MM-YYYY") !== calendarLocalDate.format("MM-YYYY")
              }
              headerRender={({ value }) => {
                const canMovePrev =
                  dayjs().tz().startOf("month") < value.startOf("month");
                const canMoveNext = checkCanMoveNextMonth(value);

                const handlePrevMonth = () => {
                  const prevMonth = value.subtract(1, "month");
                  setCalendarLocalDate(prevMonth);
                };

                const handleNextMonth = () => {
                  const nextMonth = value.add(1, "month");
                  setCalendarLocalDate(nextMonth);
                };

                return (
                  <div className={`${styles["month-changer"]} `}>
                    {!isProfilePage && (
                      <div className={styles["month"]}>
                        <p>{value.format("MMM")}</p>
                        <p>{value.format("YYYY")}</p>
                      </div>
                    )}
                    {!isProfilePage && (
                      <div className={styles["icons"]}>
                        <Button
                          type="text"
                          onClick={handlePrevMonth}
                          className={styles["icon-btn"]}
                          disabled={!canMovePrev}
                        >
                          <img
                            src={PreviousIcon}
                            alt="previous-icon"
                            className={styles["change-icons"]}
                          />
                        </Button>
                        <Button
                          type="text"
                          onClick={handleNextMonth}
                          disabled={!canMoveNext}
                          className={styles["icon-btn"]}
                        >
                          <img
                            src={NextIcon}
                            alt="previous-icon"
                            className={styles["change-icons"]}
                          />
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }}
              fullCellRender={(date: Dayjs) => {
                const slotsExists =
                  date.format("MM-YYYY") ===
                    calendarLocalDate.format("MM-YYYY") &&
                  checkDateAvailability(date);
                const isSelected =
                  selectedDate && selectedDate === date.format("DD-MM-YYYY");
                if (isProfilePage) {
                  if (
                    !(
                      date.isSameOrAfter(weekDates[0]) &&
                      date.isSameOrBefore(weekDates[1])
                    )
                  ) {
                    return null;
                  }
                }
                if (date.format("MMM") === calendarLocalDate.format("MMM")) {
                  return (
                    <div
                      className={`ant-picker-cell-inner ant-picker-calendar-date ${
                        slotsExists ? styles["has-slots"] : ""
                      } ${isSelected ? styles["custom-selected"] : ""}`}
                    >
                      <div
                        className={`ant-picker-calendar-date-value ${
                          !slotsExists && styles["no-slots"]
                        }`}
                      >
                        {date.format("D")}
                      </div>
                      {isProfilePage && (
                        <span className={styles["month-label"]}>
                          {date.format("MMM")}
                        </span>
                      )}
                    </div>
                  );
                }
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InterviewScheduler;
