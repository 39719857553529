interface IMicons {
  icon: string;
  type?: string;
  className?: string;
  style?: React.CSSProperties;
  wrapperStyle?: React.CSSProperties;
  wrapperClassName?: string;
}

const Micons = ({
  icon,
  type = "outlined",
  className,
  style,
  wrapperStyle,
  wrapperClassName,
  ...rest
}: IMicons) => {
  return (
    <div {...rest} style={wrapperStyle || {}} className={wrapperClassName}>
      <span
        className={`m-icons material-icons${
          type && type !== "" && type !== "filled" ? `-${type}` : ""
        } ${className || ""}`}
        style={style || {}}
      >
        {icon}
      </span>
    </div>
  );
};

export default Micons;
