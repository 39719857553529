import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Select, message } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import axios from "axios";

import ClockIcon from "../../../assets/images/calendly-clock-icon.svg";
import InterviewScheduler, { IAvailableDates } from "../interview-scheduler";
import BackIcon from "../../../assets/images/arrow-left-long.svg";
import TimeSlotConfirmationForm from "../interview-scheduler/time-slot-confirmation-form";
import Spinner from "../../../components/spinner";
import { timeZones } from "../../../constants";
import { getApiErrorMsg } from "../../../utils/object-util";
import TimezoneIcon from "../../../assets/images/world-time.png";
import { useAppContext } from "../../../components/contexts/appContext";
import { useQuery } from "../../../hooks/useQuery";
import { IEventDetails } from "../../../interfaces/events";

import styles from "./calendlyPublicForm.module.scss";

export interface SlotsElement {
  bookingCount: string;
  slotTime: string;
}

const CalendlyPublicForm = () => {
  const { userlink = "", eventlink = "" } = useParams();
  const navigate = useNavigate();
  const { isAutoscalLandingPage, isProfilePage } = useAppContext();
  const { query } = useQuery();
  const containerRef = useRef<HTMLDivElement>(null);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const [showFinalConfirm, setShowFinalConfirm] = useState(false);
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number | null>(
    null
  );
  const [loadingEvent, setLoadingEvent] = useState(true);
  const [loadingTimeSlots, setLoadingTimeSlots] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [selectedDateSlots, setSelectedDateSlots] = useState<
    { bookingCount: number; slotTime: string }[]
  >([]);
  const [selectedSlotData, setSelectedSlotData] = useState<any>();
  const [eventDetail, setEventDetail] = useState<IEventDetails>();
  const [availableDates, setAvailableDates] = useState<IAvailableDates[]>([]);
  const [timezoneState, setTimezoneState] = useState<string>(
    dayjs.tz.guess() === "Asia/Calcutta" ? "Asia/Kolkata" : dayjs.tz.guess()
  );

  const [calendarLocalDate, setCalendarLocalDate] = useState(dayjs().tz());

  const name = query.get("name");
  const email = query.get("email");

  useEffect(() => {
    if (isProfilePage) {
      const interval = setTimeout(() => {
        const data = {
          type: "height",
          value: containerRef?.current?.clientHeight,
        };
        window.parent.postMessage(data, "*");
      }, 10);
      return () => {
        clearTimeout(interval);
      };
    }
  });

  useEffect(() => {
    dayjs.tz.setDefault(timezoneState);
  }, [timezoneState]);

  useEffect(() => {
    if (selectedDate && availableDates.length) {
      setSelectedDateSlots(
        availableDates.find(
          (el) => dayjs(el.date).tz().format("DD-MM-YYYY") === selectedDate
        )?.slots || []
      );
    } else {
      setSelectedDateSlots([]);
    }
    setSelectedTimeIndex(null);
    setSelectedSlotData(undefined);
  }, [selectedDate, availableDates]);

  const getAvailableDatesAndSlots = async () => {
    setLoadingTimeSlots(true);
    try {
      const payload = isProfilePage
        ? {
            startDate: (calendarLocalDate.startOf("week") < dayjs()
              ? dayjs()
              : calendarLocalDate.startOf("week")
            ).format("DD-MM-YYYY"),
            endDate: calendarLocalDate.endOf("week").format("DD-MM-YYYY"),
            timezone: timezoneState,
          }
        : {
            month: Number(calendarLocalDate.format("MM")),
            year: Number(calendarLocalDate.format("YYYY")),
            timezone: timezoneState,
          };
      const response = await axios.post(
        `public/users/${userlink}/events/${eventlink}/slots/all`,
        payload
      );
      setAvailableDates(response.data?.data || []);
    } catch (err) {
      message.error({
        content: getApiErrorMsg(err),
        key: "error",
        duration: 2,
      });
    } finally {
      setSelectedDate("");
      setSelectedTimeIndex(null);
      setSelectedSlotData(undefined);
      setLoadingTimeSlots(false);
    }
  };

  const getEventDetails = async () => {
    setLoadingEvent(true);
    try {
      const response = await axios.get(
        `public/users/${userlink}/events/${eventlink}`
      );
      setEventDetail(response.data.data);
    } catch (err) {
      message.error({
        content: getApiErrorMsg(err),
        key: "event",
        duration: 2,
      });
    } finally {
      setLoadingEvent(false);
    }
  };

  useEffect(() => {
    getEventDetails();
  }, []);

  useEffect(() => {
    getAvailableDatesAndSlots();
  }, [timezoneState, calendarLocalDate]);

  return (
    <>
      {loadingEvent ? (
        <Spinner />
      ) : eventDetail ? (
        <div
          ref={containerRef}
          className={`${styles["calendar-page-container"]} ${
            isAutoscalLandingPage
              ? styles["calendar-page-container-landing"]
              : ""
          } ${isProfilePage ? styles["calendar-page-container-profile"] : ""}`}
        >
          {!isAutoscalLandingPage && !isProfilePage && (
            <div className={styles["calendar-left-content"]}>
              <img
                src={BackIcon}
                alt="back-icon"
                onClick={() => {
                  if (showFinalConfirm) {
                    navigate(
                      `/${userlink}/${eventlink}?${query.toString() || ""}`
                    );
                    setShowFinalConfirm(!showFinalConfirm);
                  } else {
                    navigate(`/${userlink}`);
                  }
                }}
                style={{ cursor: "pointer" }}
              />
              <p className={styles["session-details"]}>
                {eventDetail.user.firstName + " " + eventDetail.user.lastName}
              </p>
              <p className={styles["stage-details"]}>{eventDetail.name}</p>

              <p className={styles["time-detail"]}>
                <span>
                  <img
                    src={ClockIcon}
                    alt="clock-icon"
                    style={{ width: "14px", height: "13px" }}
                  />
                </span>
                {eventDetail.duration} {eventDetail.durationUnit}
              </p>

              <p className={styles["time-detail"]}>{eventDetail.description}</p>
              {eventDetail?.isPayable && (
                <p className={styles["stage-details"]}>
                  Amount:
                  {eventDetail.currency === "INR" ? (
                    <span style={{ marginLeft: 5 }}>&#8377;</span>
                  ) : (
                    <span>&#36;</span>
                  )}
                  <span> {eventDetail.amount?.toFixed(2)}</span>
                </p>
              )}

              {showFinalConfirm &&
              (selectedTimeIndex || selectedTimeIndex === 0) ? (
                <>
                  <p className={styles["time-detail"]}>
                    <span>
                      <CalendarOutlined />
                    </span>
                    {`${dayjs(selectedSlotData.slotTime)
                      .tz()
                      .format("hh:mm A")} - ${dayjs(selectedSlotData.slotTime)
                      .tz()
                      .add(eventDetail.duration, eventDetail.durationUnit)
                      .format("hh:mm A")}, ${dayjs(selectedDate, "DD-MM-YYYY")
                      // .tz()
                      .format("dddd, MMMM D YYYY")}`}
                  </p>
                  <div
                    style={{
                      color: "#898989",
                      display: "flex",
                    }}
                  >
                    <img
                      src={TimezoneIcon}
                      alt="timezone"
                      height={15}
                      width={15}
                      style={{ marginRight: 10 }}
                    />
                    <span style={{ fontSize: 14, fontWeight: 500 }}>
                      {timezoneState}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <div style={{ display: "flex" }}>
                    <img
                      src={TimezoneIcon}
                      alt="timezone"
                      height={15}
                      width={15}
                      style={{ marginRight: 10 }}
                    />
                    <span
                      style={{
                        color: "#898989",
                        fontSize: 14,
                        fontWeight: 500,
                      }}
                    >
                      Timezone
                    </span>
                  </div>
                  <Select
                    showSearch
                    value={timezoneState}
                    options={timeZones.map((el) => ({ label: el, value: el }))}
                    onChange={setTimezoneState}
                    style={{ width: 200, marginTop: 10 }}
                  />
                </>
              )}
            </div>
          )}

          {(isAutoscalLandingPage || isProfilePage || !showFinalConfirm) && (
            <div className={styles["calendar-right-content"]}>
              {eventDetail && (
                <InterviewScheduler
                  selectedDate={selectedDate}
                  setSelectedDate={setSelectedDate}
                  calendarLocalDate={calendarLocalDate}
                  setCalendarLocalDate={setCalendarLocalDate}
                  loading={loadingTimeSlots}
                  availableDates={availableDates}
                  eventDetail={eventDetail}
                />
              )}
                {selectedDate && (
                <>
                  {isProfilePage && (
                    <p className={styles["date-selected-label"]}>Select Time</p>
                  )}

                  <p className={styles["date-selected"]}>
                    {isProfilePage && (
                      <span className={styles["meeting-span"]}>
                       {`${eventDetail.duration} ${eventDetail.durationUnit} meetings `}
                      </span>
                    )}
                    {timezoneState}
                    {selectedSlotData && (
                      <span style={{ marginLeft: "5px" }}>
                        Time ({" "}
                        {dayjs(selectedSlotData?.slotTime).format("HH:mm A")})
                      </span>
                    )}
                  </p>
                  <div className={styles["right-scroll"]}>
                    {selectedDateSlots.map((slot, index: number) => (
                      <Button
                        className={`${styles["time-slot-btn"]} ${
                          selectedTimeIndex === index
                            ? `${styles["selected-time-slot-btn-wrapper"]}`
                            : ""
                        }`}
                        onClick={() => {
                          setSelectedTimeIndex(index);
                          setSelectedSlotData({
                            slotTime: slot.slotTime,
                          });
                        }}
                        key={index}
                      >
                        {dayjs(slot.slotTime).tz().format("h:mm a")}
                      </Button>
                    ))}
                  </div>
                  {!isAutoscalLandingPage &&
                    !isProfilePage &&
                    (selectedTimeIndex || selectedTimeIndex === 0) && (
                      <Button
                        type="primary"
                        className={styles["time-slot-confirm-btn"]} // css to be fixed
                        onClick={() => {
                          setShowFinalConfirm(true);
                        }}
                      >
                        Confirm
                      </Button>
                    )}
                </>
              )}
            </div>
          )}

          {(isAutoscalLandingPage ||
            isProfilePage ||
            ((selectedTimeIndex || selectedTimeIndex === 0) &&
              showFinalConfirm)) && (
            <TimeSlotConfirmationForm
              event={eventDetail}
              userlink={userlink}
              eventlink={eventlink}
              // selectedTime={dayjs(selectedSlotData.slotTime)
              //   .tz()
              //   .format("hh:mm A")}
              selectedDate={selectedDate}
              selectedSlotData={selectedSlotData}
              timezone={timezoneState}
              userData={{ name, email }}
            />
          )}
        </div>
      ) : (
        <>Unable to load details....</>
      )}
    </>
  );
};

export default CalendlyPublicForm;
