import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  DatePicker,
  Radio,
  Button,
  Select,
  Checkbox,
  Collapse,
  message,
  InputNumber,
} from "antd";
import axios from "axios";
import dayjs from "dayjs";

import { Input } from "../../../components/form-fields/input";
import {
  eventTimeBufferOptions,
  eventDurationOptions,
} from "../../../constants";
import { getApiErrorMsg } from "../../../utils/object-util";
import Schedule, { ISchedule } from "../../availability/schedule";

import styles from "./form.module.scss";

interface ISectionProps {
  description?: string;
  children: React.ReactNode;
  extra?: React.ReactNode;
}

interface IFormStepTwo {
  mode: string;
  formData: any;
  id: string;
  type: string | null;
  onSubmit: (data: any) => void;
  setActiveKey: (arg: string) => void;
  isSubmitting: boolean;
}

const { RangePicker } = DatePicker;
const { Item } = Form;

const Step2 = ({
  mode,
  formData,
  onSubmit,
  setActiveKey,
  type,
  isSubmitting,
}: IFormStepTwo) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [schedules, setSchedules] = useState<any[]>([]);
  const [weekdayAvailabilities, setWeekdayAvailabilities] = useState<any[]>([]);
  const [dateOverrides, setDateOverrides] = useState<any[]>([]);
  const [customSchedule, setCustomSchedule] = useState<any>();
  const watchScheduleType = Form.useWatch("scheduleType", form);
  const watchDuration = Form.useWatch("duration", form);
  const watchCustomDurationUnit = Form.useWatch("customDurationUnit", form);
  const watchBuffer = Form.useWatch("buffer", form);
  const watchSelectedSchedule = Form.useWatch("scheduleId", {
    form,
    preserve: true,
  });
  const watchAvailabilityType = Form.useWatch("availabilityType", {
    form,
    preserve: true,
  });

  //Get all schedules
  useEffect(() => {
    axios
      .post("schedules/all")
      .then((response) => {
        if (response.data.data) {
          setSchedules(response.data.data);
          if (mode === "create") {
            const schedule = response.data.data.find(
              (el: any) => el.isDefault === true
            );
            if (schedule) {
              form.setFieldValue("scheduleId", schedule.id);
            }
          }
        }
      })
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "error",
          duration: 2,
        });
      });
  }, []);

  //Populating formdata
  useEffect(() => {
    if (formData) {
      const isCustomSchedule = formData?.schedule?.isCustom;
      form.setFieldsValue({
        ...formData,
        range:
          formData.startDate && formData.endDate
            ? [dayjs(formData.startDate), dayjs(formData.endDate)]
            : [dayjs().startOf("day"), dayjs().add(1, "month").endOf("day")],
        buffer: !!formData.inbetweenBufferDuration,
        ...(formData?.durationUnit === "minutes"
          ? eventDurationOptions.find((el: any) => {
              return el.value === formData.duration;
            })
            ? { duration: formData.duration }
            : {
                duration: "custom",
                customDuration: formData.duration,
                customDurationUnit: "minutes",
              }
          : {
              duration: "custom",
              customDuration: formData.duration,
              customDurationUnit: "hours",
            }),
        ...(isCustomSchedule
          ? { availabilityType: "custom" }
          : { availabilityType: "existing", scheduleId: formData.scheduleId }),
      });
    }
  }, [mode, formData]);

  //Weekday availabilites, dateoverrides list and custom schedule
  useEffect(() => {
    if (watchSelectedSchedule) {
      const selectedSchedule = schedules.find(
        (schedule: any) => schedule.id === watchSelectedSchedule
      );
      setWeekdayAvailabilities(
        selectedSchedule?.availabilities.filter(
          (el: any) => el.type === "WeekDay"
        ) || []
      );
      setDateOverrides(
        selectedSchedule?.availabilities.filter(
          (el: any) => el.type === "Date"
        ) || []
      );
      setCustomSchedule(selectedSchedule);
    }
  }, [watchSelectedSchedule, schedules]);

  useEffect(() => {
    if (watchAvailabilityType) {
      if (watchAvailabilityType === "existing") {
        const existingSchedule = schedules.find(
          (el) => el.id === watchSelectedSchedule
        );
        if (!existingSchedule) {
          const defaultSchedule = schedules.find((el) => el.isDefault);
          if (defaultSchedule) {
            form.setFieldValue("scheduleId", defaultSchedule.id);
          } else if (schedules.length) {
            form.setFieldValue("scheduleId", schedules[0].id);
          }
        }
      } else {
        const schedule =
          schedules.find((el: any) => el.id === watchSelectedSchedule) ||
          (formData?.schedule?.isCustom ? formData.schedule : undefined);
        if (schedule) {
          const editedSchedule = {
            ...schedule,
            availabilities: schedule.availabilities.map((item: any) => {
              const newItem = { ...item };
              if (item.type === "Date") {
                delete newItem.day;
              } else {
                delete newItem.date;
              }
              return newItem;
            }),
            isCustom: true,
          };
          delete editedSchedule.id;
          setCustomSchedule(editedSchedule);
        }
      }
    }
  }, [watchAvailabilityType]);

  const saveNewSchedule = (schedule: ISchedule) => {
    form.setFieldsValue({
      availabilityType: "existing",
      scheduleId: schedule.id,
    });
    setSchedules((prev) => [schedule, ...prev]);
  };

  const handleSubmit = (data: any) => {
    const finalData: any = {};
    //Date range data
    if (data.scheduleType === "DaysCount") {
      finalData.daysCount = Number(data.daysCount);
      finalData.countType = data.countType;
    } else if (data.scheduleType === "DateRange") {
      finalData.startDate = dayjs(data.range[0]).format();
      finalData.endDate = dayjs(data.range[1]).format();
    }
    finalData.scheduleType = data.scheduleType;

    //Duration data
    finalData.duration =
      data.duration === "custom"
        ? Number(data.customDuration)
        : Number(data.duration);
    finalData.durationUnit =
      data.duration === "custom" ? data.customDurationUnit : "minutes";

    // buffer time
    finalData.inbetweenBufferDuration = data?.buffer
      ? data.inbetweenBufferDuration
      : 0;

    //Additional options

    //Scheduling condtions
    finalData.minimumStartBuffer = Number(data.minimumStartBuffer);
    finalData.minimumStartBufferUnit = data.minimumStartBufferUnit;

    //max allowed event
    if (data?.maxBookingCount)
      finalData.maxBookingCount = Number(data.maxBookingCount);

    // Is secret event
    finalData.isSecret = data.isSecret;

    //Schedule id
    if (watchAvailabilityType === "existing") {
      finalData.scheduleId = data.scheduleId;
    } else {
      finalData.schedule = customSchedule;
      //Check if day and date need to be removed
    }
    onSubmit(finalData);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{
          scheduleType: "DaysCount",
          daysCount: "60",
          countType: "CalendarDays",
          duration: 30,
          beforeEvent: false,
          minimumStartBuffer: 4,
          minimumStartBufferUnit: "hours",
          isSecret: false,
        }}
        disabled={isSubmitting}
      >
        <Section description="Set a range of dates when you can accept meetings.">
          <h3>Date range</h3>
          <p>Invitees can schedule ...</p>
          <Item name="scheduleType">
            <Radio.Group
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
                marginTop: 20,
              }}
            >
              <Radio value="DaysCount">
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  <Item
                    style={{ marginBottom: 0, width: 100 }}
                    name="daysCount"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value && watchScheduleType === "DaysCount") {
                            if (value === "-") {
                              return Promise.reject("Days cannot be negative");
                            } else if (value === "0") {
                              return Promise.reject("Days cannot be zero");
                            } else if (String(value).startsWith("0")) {
                              form.setFieldValue(
                                "daysCount",
                                value.slice(1, value.length)
                              );
                            } else {
                              return Promise.resolve();
                            }
                          } else {
                            return Promise.resolve();
                          }
                        },
                      },
                    ]}
                  >
                    <Input type="number" min={1} />
                  </Item>

                  <Item name="countType" style={{ marginBottom: 0 }}>
                    <Select
                      style={{ width: 180 }}
                      onClick={(e) => e.preventDefault()}
                      options={[
                        {
                          label: "Calendar Days",
                          value: "CalendarDays",
                        },
                        {
                          label: "Week days",
                          value: "WeekDays",
                        },
                      ]}
                    />
                  </Item>
                  <p>into the future</p>
                </div>
              </Radio>

              <Radio value="DateRange">
                <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
                  Within a date range
                  {watchScheduleType === "DateRange" && (
                    <Item
                      name="range"
                      style={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: watchScheduleType === "DateRange",
                          message: "Please select the date range.",
                        },
                      ]}
                      className={styles["range"]}
                    >
                      <RangePicker
                        style={{ marginBottom: 0 }}
                        onClick={(e) => e.preventDefault()}
                      />
                    </Item>
                  )}
                </div>
              </Radio>
              <Radio value="Indefinite">Indefinitely into the future</Radio>
            </Radio.Group>
          </Item>
        </Section>
        <Section description="Define how long your event will be. It can be as long as 12 hours.">
          <h3 style={{ marginBottom: 10 }}>Duration</h3>
          <Item name="duration">
            <Select
              style={{ width: "80%" }}
              options={eventDurationOptions}
              onChange={(value) => {
                if (value === "custom")
                  form.setFieldValue("customDurationUnit", "minutes");
              }}
            />
          </Item>
          {watchDuration === "custom" && (
            <div style={{ display: "flex", width: "80%", gap: 20 }}>
              <Item
                style={{ marginBottom: 0, width: "60%" }}
                name="customDuration"
                dependencies={["customDurationUnit"]}
                rules={[
                  {
                    validator: async (_, value) => {
                      if (value) {
                        if (value === "-") {
                          return Promise.reject("Days cannot be negative");
                        } else if (value === "0") {
                          return Promise.reject("Days cannot be zero");
                        } else if (String(value).startsWith("0")) {
                          form.setFieldValue(
                            "daysCount",
                            value.slice(1, value.length)
                          );
                        } else if (
                          Number(value) >
                          (watchCustomDurationUnit === "hours" ? 12 : 720)
                        ) {
                          return Promise.reject(
                            `Must be less than or equal to ${
                              watchCustomDurationUnit === "hours" ? 12 : 720
                            }`
                          );
                        } else {
                          return Promise.resolve();
                        }
                      }
                    },
                  },
                  //   { required: true, message: "Required" },
                ]}
              >
                <Input type="number" min={1} />
              </Item>
              <Item
                name="customDurationUnit"
                style={{ marginBottom: 0, width: "40%" }}
              >
                <Select
                  options={[
                    { label: "hrs", value: "hours" },
                    { label: "mins", value: "minutes" },
                  ]}
                  style={{ width: "100%" }}
                />
              </Item>
            </div>
          )}
        </Section>
        <Section
          description="Select one of this schedule for this type of event"
          extra={
            watchAvailabilityType === "existing" ? (
              <ScheduleList
                weekdayAvailabilities={weekdayAvailabilities}
                dateOverrides={dateOverrides}
              />
            ) : (
              <div className={styles["custom-schedule-wrapper"]}>
                <Schedule
                  isEvent
                  customSchedule={customSchedule}
                  setCustomSchedule={setCustomSchedule}
                  saveNewSchedule={saveNewSchedule}
                />
              </div>
            )
          }
        >
          <h3 style={{ marginBottom: 10 }}>
            Which schedule do you want to use?
          </h3>
          <Item name="availabilityType">
            <Radio.Group
              options={[
                { label: "Use an existing schedule", value: "existing" },
                { label: "Set custom hours", value: "custom" },
              ]}
              optionType="button"
            />
          </Item>
          {watchAvailabilityType === "existing" ? (
            <Item name="scheduleId">
              <Select
                options={schedules.map((schedule) => ({
                  label: schedule.name,
                  value: schedule.id,
                }))}
                style={{ width: 300 }}
              />
            </Item>
          ) : (
            <></>
          )}
        </Section>
        <Section description="Give yourself some buffer time to prepare for or wrap up from booked Calendly events.">
          <h3>Buffer Time between meetings</h3>
          <Item name="buffer" valuePropName="checked">
            <Checkbox>Add buffer</Checkbox>
          </Item>
          <Item name="inbetweenBufferDuration">
            <Select
              style={{ width: 300 }}
              disabled={!watchBuffer}
              options={eventTimeBufferOptions}
            />
          </Item>
        </Section>
        <Collapse
          items={[
            {
              key: "1",
              label: "Additional Rules",
              forceRender: true,
              children: <AdditionalRules type={type} />,
              className: styles["additional-rules"],
              style: { borderRadius: 0, background: "none" },
            },
          ]}
        />
        <div className={`${styles["submit-btn-cont"]} ${styles["step-two"]}`}>
          <Button
            onClick={() => {
              if (mode === "edit") {
                setActiveKey("0");
              } else {
                navigate("/events");
              }
            }}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button onClick={form.submit} type="primary" loading={isSubmitting}>
            {mode === "edit" ? "Save and update" : "Next"}
          </Button>
        </div>
      </Form>
    </>
  );
};

const AdditionalRules = ({ type }: { type: string | null }) => {
  return (
    <>
      <Section description="Set the minimum amount of notice that is required and how many events are allowed per day.">
        <h3>Scheduling conditions</h3>
        <p style={{ marginBottom: 10 }}>{`Invitees can't schedule within`}</p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
            marginBottom: 20,
          }}
        >
          <Item
            name="minimumStartBuffer"
            style={{ width: 100, marginBottom: 0 }}
          >
            <Input type="number" />
          </Item>
          <Item
            name="minimumStartBufferUnit"
            style={{ width: "30%", marginBottom: 0 }}
          >
            <Select
              options={[
                { label: "hours", value: "hours" },
                { label: "minutes", value: "minutes" },
                { label: "days", value: "days" },
              ]}
            />
          </Item>
          <p>of an event start time</p>
        </div>
        <p style={{ marginBottom: 10 }}>
          {type === "Group"
            ? "Maximum number of participants allowed in one slot"
            : "Maximum allowed event per day for this kind of event"}
        </p>
        <Item
          name="maxBookingCount"
          style={{ width: 100 }}
          rules={[
            {
              validator: async (_, value) => {
                if (!value) {
                  return Promise.reject("Please enter a number.");
                }

                // const pattern = /^\d*$/;
                const pattern = /^[1-9]\d*$/;
                if (pattern.test(value)) {
                  return Promise.resolve();
                }

                return Promise.reject("Please enter a whole number.");
              },
            },
          ]}
        >
          <InputNumber type="number" min={1} max={100} />
        </Item>
      </Section>

      <Section description="Use this to hide the event on your main scheduling page.">
        <h3>Secret Event</h3>
        <Item name="isSecret" valuePropName="checked">
          <Checkbox>Make this a secret event</Checkbox>
        </Item>
        <p>Only invitees who have this link can schedule with you.</p>
      </Section>
    </>
  );
};

const Section = ({ description, children, extra }: ISectionProps) => {
  return (
    <>
      <Row className={styles.section}>
        <Col sm={14}>{children} </Col>
        <Col sm={10} style={{ fontSize: 16 }}>
          {description || ""}
        </Col>
        <Col sm={24}>{extra && extra}</Col>
      </Row>
    </>
  );
};

const ScheduleList = ({
  weekdayAvailabilities,
  dateOverrides,
}: {
  weekdayAvailabilities: any;
  dateOverrides: any;
}) => {
  return (
    <div className={styles["schedule-cont"]} style={{ width: "100%" }}>
      <Row>
        <Col sm={14}>
          <div className={styles["weekday-list"]}>
            <h3>WEEKLY HOURS</h3>
            {weekdayAvailabilities?.map((el: any) => {
              return (
                <Row key={el.id}>
                  <Col sm={5} className={`${styles["bold"]} ${styles["day"]}`}>
                    {el.day.slice(0, 3).toUpperCase()}
                  </Col>
                  <Col sm={19}>
                    {el.availabilityTimes?.length > 0 ? (
                      el.availabilityTimes?.map((times: any) => {
                        return (
                          <p key={times.id} className={styles.time}>
                            {`${dayjs(times.startTime).format(
                              "hh:mm A"
                            )} - ${dayjs(times.endTime).format("hh:mm A")}`}
                          </p>
                        );
                      })
                    ) : (
                      <p>Unavailable</p>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
        </Col>
        <Col sm={10}>
          <div className={styles["date-override-list"]}>
            <h3>DATE OVERRIDES</h3>
            {dateOverrides.length > 0 ? (
              dateOverrides?.map((el: any) => {
                return (
                  <Row key={el.id}>
                    <Col
                      sm={8}
                      className={`${styles["bold"]} ${styles["date"]}`}
                    >
                      {dayjs(el.date).format("DD MMM YYYY")}
                    </Col>
                    <Col sm={16}>
                      {el.availabilityTimes?.length > 0 ? (
                        el.availabilityTimes?.map((times: any) => {
                          return (
                            <p key={times.id} className={styles.time}>
                              {`${dayjs(times.startTime).format(
                                "hh:mm A"
                              )} - ${dayjs(times.endTime).format("hh:mm A")}`}
                            </p>
                          );
                        })
                      ) : (
                        <p>Unavailable</p>
                      )}
                    </Col>
                  </Row>
                );
              })
            ) : (
              <>
                To override your hours on specific dates, update your schedule
                under Availability
              </>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Step2;
