import { Input } from "antd";

import styles from "./textArea.module.scss";

interface TextAreaProps {
  rows?: number;
  col?: number;
  autoSize?: {
    minRows?: number;
    maxRows?: number;
  };
  placeholder?: string;
  value?: any;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  wrapperStyle?: React.CSSProperties;
  className?: string;
}

export const TextArea = ({
  rows,
  col,
  autoSize,
  placeholder,
  value,
  className,
  onChange,
  wrapperStyle,
}: TextAreaProps) => {
  return (
    <div className={`${styles["wrapper"]} ${className}`} style={wrapperStyle}>
      <Input.TextArea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        rows={rows}
        cols={col}
        autoSize={autoSize}
      />
    </div>
  );
};
