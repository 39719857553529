import { Row, Col } from "antd";
import { useNavigate } from "react-router-dom";

import Micons from "../../../components/customAntd/micons";
import { eventTypeDetails } from "../../../constants";

import styles from "./types.module.scss";

interface IEventTypes {
  name: string;
  desc: string;
  example: string;
  icon: string;
  type?: string | undefined;
}

const CreateEventTypes = () => {
  const navigate = useNavigate();
  const handleOnClick = (type: string | undefined) => {
    if (type) {
      navigate(`/events/form?mode=create&type=${type}`);
    }
  };

  return (
    <>
      <h2 className={styles.title}>Create New Event Type</h2>
      <div className={styles.wrapper}>
        {eventTypeDetails.map(
          ({ name, desc, example, icon, type }: IEventTypes) => {
            return (
              <Row
                key={name}
                onClick={() => {
                  handleOnClick(type);
                }}
              >
                <Col sm={4}>
                  <Micons icon={icon} wrapperClassName={styles.icon} />
                </Col>
                <Col sm={18}>
                  <p>{name}</p>
                  <p className={styles.desc}>{desc}</p>
                  <p>{example}</p>
                </Col>
                <Col sm={2}>
                  <Micons
                    icon="keyboard_arrow_right"
                    wrapperClassName={styles.icon}
                  />
                </Col>
              </Row>
            );
          }
        )}
      </div>
    </>
  );
};

export default CreateEventTypes;
