import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col } from "antd";
import axios from "axios";

import { Events } from "../../../interfaces/events";
import EventsWrapper from "../../../components/eventsWrapper";
import Micons from "../../../components/customAntd/micons";
import Spinner from "../../../components/spinner";
import { getApiErrorMsg } from "../../../utils/object-util";

import styles from "./allevents.module.scss";

const AllEvents = () => {
  const { userlink = "" } = useParams();
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [eventsResponse, setEventsResponse] = useState<Events>();

  const loadAllEvents = () => {
    (async function () {
      setLoadingEvents(true);
      setErrMsg("");
      axios
        .get(`public/users/${userlink}`)
        .then((response) => {
          setEventsResponse(response.data.data);
        })
        .catch((err) => setErrMsg(getApiErrorMsg(err)))
        .finally(() => {
          setLoadingEvents(false);
        });
    })();
  };

  useEffect(() => {
    loadAllEvents();
  }, []);

  return (
    <>
      {loadingEvents ? (
        <Spinner />
      ) : (
        <EventsWrapper>
          {eventsResponse ? (
            <>
              <p
                className={styles["title"]}
              >{`${eventsResponse?.firstName} ${eventsResponse?.lastName}`}</p>
              <p className={styles["description"]}>
                Welcome to my scheduling page. Please follow the instructions to
                add an event to my calendar.
              </p>
              <div className={styles["cards-wrapper"]}>
                <Row gutter={20}>
                  {eventsResponse?.events.map(
                    (data: any, dataIndex: number) => {
                      return (
                        <Col key={dataIndex} sm={12} md={8} lg={12}>
                          <Card
                            color={data.color}
                            link={data.link}
                            name={data.name}
                            description={data.description}
                          />
                        </Col>
                      );
                    }
                  )}
                </Row>
              </div>
            </>
          ) : (
            <p className={styles["title"]}>{errMsg}</p>
          )}
        </EventsWrapper>
      )}
    </>
  );
};

const Card = ({ color, link, name, description }: any) => {
  const navigate = useNavigate();
  const { userlink = "" } = useParams();
  const handleClick = () => {
    navigate(`/${userlink}/${link}`);
  };

  return (
    <div className={styles["event-card-container"]} onClick={handleClick}>
      <div className={styles["event-card"]}>
        <div
          className={styles["profile-color"]}
          style={{ backgroundColor: `${color}` }}
        ></div>
        <div className={styles["meeting-time"]}>{name}</div>
        <Micons icon="arrow_right" style={{ fontSize: 36 }} />
      </div>
      <div className={styles["desc"]}>{description}</div>
    </div>
  );
};

export default AllEvents;
