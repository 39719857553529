import { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Modal,
  Switch,
  Checkbox,
  Select,
  Button,
  message,
} from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { Input } from "../../../components/form-fields/input";
import { TextArea } from "../../../components/form-fields/text-area";
import Micons from "../../../components/customAntd/micons";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";

import styles from "./form.module.scss";

const { Item } = Form;

interface IInviteeQuestionsProps {
  mode: string | null;
  id: string | null;
  type: string | null;
  setActiveKey: (arg: string) => void;
}

interface IInviteeQuestions {
  id?: string;
  question: string;
  type: string;
  isActive: boolean;
  isRequired: boolean;
  options: string[];
  placeholder?: string;
  defaultValue?: string;
}

const InviteeQuestions = ({
  mode,
  id,
  type,
  setActiveKey,
}: IInviteeQuestionsProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [questions, setQuestions] = useState<IInviteeQuestions[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(0);
  const [currentDefaultOption, setCurrentDefaultOption] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const watchOptionsType = Form.useWatch("type", form);
  const watchOptionsList = Form.useWatch("optionsList", form);

  const handleOptionInputChange = (index: number, value: string) => {
    const updatedOptions = [...watchOptionsList];
    updatedOptions[index] = { option: value }; // Update the specific option at the given index
    form.setFieldsValue({
      optionsList: updatedOptions, // Update the form field value with the modified array
    });
  };

  const handleDefaultOptionChange = (value: any) => {
    setCurrentDefaultOption(value);
  };

  const removeOption = (index: number) => {
    const fieldsValue = form.getFieldValue("optionsList");
    const removedOptionValue = fieldsValue[index]?.option;

    const updatedOptionsList = fieldsValue.filter(
      (_: any, i: any) => i !== index
    );

    if (currentDefaultOption === removedOptionValue) {
      setCurrentDefaultOption(updatedOptionsList[0]?.option || "");
    }

    form.setFieldsValue({
      optionsList: updatedOptionsList,
      defaultValue:
        currentDefaultOption === removedOptionValue
          ? updatedOptionsList[0]?.option || ""
          : currentDefaultOption,
    });
  };

  useEffect(() => {
    axios
      .get(`events/${id}/invitee-questions`)
      .then((response) => {
        if (response.data?.data) {
          const questions = response.data.data.filter(
            (el: any) => !["Name", "Email"].includes(el.question)
          );
          setQuestions(questions);
        }
      })
      .catch((error) => {
        message.error({
          content: getApiErrorMsg(error),
          key: "error",
          duration: 2,
        });
      });
  }, []);

  useEffect(() => {
    if (["OneLine", "MultipleLines"].includes(watchOptionsType)) {
      form.setFieldsValue({
        optionsList: [], // Clear the optionsList field in the form
      });
    }
  }, [watchOptionsType]);

  const handleQuestionAdd = (data: any) => {
    if (!isEdit) {
      setQuestions((prev: any) => [
        ...prev,
        {
          question: data.question,
          type: data.type,
          options: data?.optionsList?.map((op: any) => op.option) || [],
          isActive: !!data.isActive,
          isRequired: !!data.isRequired,
          placeholder: data.placeholder,
          defaultValue:
            data.type === "Checkboxes" && data.defaultValue
              ? data.defaultValue.join(",") // Convert array to comma-separated string
              : data.defaultValue || "", // Use the provided defaultValue as is
        },
      ]);
    } else {
      setQuestions((prev) => {
        return prev.map((el: any, index) => {
          return index === editIndex
            ? {
                question: data.question,
                type: data.type,
                options: data?.optionsList?.map((op: any) => op.option) || [],
                isActive: !!data.isActive,
                isRequired: !!data.isRequired,
                placeholder: data.placeholder,
                defaultValue:
                  data.type === "Checkboxes" && data.defaultValue
                    ? data.defaultValue.join(",") // Convert array to comma-separated string
                    : data.defaultValue || "", // Use the provided defaultValue as is
              }
            : el;
        });
      });
    }
    setIsEdit(false);
    setEditIndex(0);
    setIsOpen(false);
  };

  const handleQuestionDelete = () => {
    setQuestions((prev: any) => {
      const questions = [...prev];
      questions.splice(editIndex, 1);
      return questions;
    });
    setIsOpen(false);
    setEditIndex(0);
    setIsEdit(false);
  };

  const handleSubmit = () => {
    setIsSubmitting(true);
    // Create a new array with questions without the defaultValue and placeholder fields if they are empty

    const submitQuestions = questions.map((question) => {
      const updatedQuestion: IInviteeQuestions = {
        ...question,
      };

      if (!question.defaultValue) {
        delete updatedQuestion.defaultValue;
      }
      if (!question.placeholder) {
        delete updatedQuestion.placeholder;
      }
      return updatedQuestion;
    });
    axios
      .put(`events/${id}/invitee-questions`, [...submitQuestions])
      .then((response) => {
        if (response?.data?.data) {
          setQuestions(questions);
          message.success({
            content: getApiResponseMsg(response),
            key: "success",
            duration: 2,
          });
        }
      })
      .catch((error) => {
        message.error({
          content: getApiErrorMsg(error),
          key: "error",
          duration: 2,
        });
      })
      .finally(() => {
        if (mode === "create") {
          navigate(
            `/events/form?mode=edit&id=${id}&type=${type}&isCreate=false`
          );
        } else {
          setActiveKey("0");
        }
        setIsSubmitting(false);
      });
  };

  const handleQuestionEdit = (index: number) => {
    setIsEdit(true);
    setEditIndex(index);

    // Set currentDefaultOption to the defaultValue of the currently edited question
    setCurrentDefaultOption(questions[index]?.defaultValue || "");

    form.setFieldsValue({
      ...questions[index],
      defaultValue:
        questions[index]?.type === "Checkboxes"
          ? questions[index]?.defaultValue?.split(",") || []
          : questions[index]?.defaultValue || "",
      optionsList: questions[index]?.options?.map((option: string) => {
        return {
          option: option,
        };
      }),
    });
    setIsOpen(true);
  };

  return (
    <>
      <Modal
        open={isOpen}
        okText="Apply"
        cancelText="Cancel"
        onCancel={() => {
          setIsOpen(false);
          setCurrentDefaultOption(""); // Reset the currentDefaultOption to an empty string
          form.resetFields(); // Reset the form fields
        }}
        closeIcon={false}
        width={400}
        className={styles["question-modal"]}
        cancelButtonProps={{
          onClick: () => {
            setIsOpen(false);
            setCurrentDefaultOption(""); // Reset the currentDefaultOption to an empty string
            form.resetFields(); // Reset the form fields
          },
        }}
        okButtonProps={{ onClick: () => form.submit() }}
        destroyOnClose
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{
            type: "OneLine",
            optionsList: [{ option: "" }],
            defaultValue: currentDefaultOption || "",
          }}
          onFinish={handleQuestionAdd}
          preserve={false}
        >
          <Item
            name="question"
            rules={[{ required: true, message: "Can't be blank" }]}
            label="Question"
          >
            <TextArea rows={5} placeholder="Enter your question here" />
          </Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Item name="isActive" valuePropName="checked" initialValue={true}>
              <Switch checkedChildren="ON" unCheckedChildren="OFF" />
            </Item>
            <Item name="isRequired" valuePropName="checked">
              <Checkbox>Required</Checkbox>
            </Item>
          </div>
          <Item name="type" label="Answer type">
            <Select
              options={[
                { label: "One Line", value: "OneLine" },
                { label: "Multiple Line", value: "MultipleLines" },
                { label: "Radio Buttons", value: "RadioButtons" },
                { label: "Checkboxes", value: "Checkboxes" },
                { label: "Dropdown", value: "Dropdown" },
                // {label: "Phone Number", value: "PhoneNumber"}
              ]}
            />
          </Item>
          {["OneLine", "MultipleLines"].includes(watchOptionsType) && (
            <>
              <Form.Item name="placeholder" label="Placeholder">
                <Input placeholder="Enter the placeholder for your Question" />
              </Form.Item>
            </>
          )}

          {!["OneLine", "MultipleLines"].includes(watchOptionsType) && (
            <>
              <Form.List name="optionsList">
                {(fields, { add }) => {
                  return (
                    <>
                      <p>Answers *</p>
                      <p style={{ marginBottom: 10 }}>
                        Invitees can select one of the following
                      </p>
                      {fields.map(({ name, key }) => {
                        const errorMessages = form.getFieldError([name]);
                        return (
                          <Row key={key} gutter={[20, 20]}>
                            <Col sm={20}>
                              <Item
                                style={{ marginBottom: 10 }}
                                name={[name, "option"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Can't be blank.",
                                  },
                                  () => ({
                                    validator(rule, value) {
                                      const formValues = form.getFieldsValue([
                                        "optionsList",
                                      ]);
                                      const optionsList =
                                        formValues.optionsList || [];

                                      const duplicate = optionsList.some(
                                        (option: any, index: any) =>
                                          index !== key &&
                                          option &&
                                          option.option &&
                                          option.option === value
                                      );

                                      if (duplicate) {
                                        return Promise.reject(
                                          "Duplicate options are not allowed."
                                        );
                                      }
                                      return Promise.resolve();
                                    },
                                  }),
                                ]}
                              >
                                <Input
                                  placeholder={`Option ${1}`}
                                  onChange={(e) =>
                                    handleOptionInputChange(key, e.target.value)
                                  }
                                />
                              </Item>
                              {errorMessages && errorMessages.length > 0 && (
                                <div style={{ color: "red" }}>
                                  {errorMessages.map((error, index) => (
                                    <div key={index}>{error}</div>
                                  ))}
                                </div>
                              )}
                            </Col>
                            <Col sm={4}>
                              {fields.length > 0 && (
                                <Button
                                  icon={<Micons icon="delete" />}
                                  onClick={() => removeOption(key)}
                                ></Button>
                              )}
                            </Col>
                          </Row>
                        );
                      })}
                      <Button
                        icon={<Micons icon="add" style={{ fontSize: 16 }} />}
                        className={styles["add-option"]}
                        onClick={() => add()}
                        type="link"
                      >
                        Add another
                      </Button>
                    </>
                  );
                }}
              </Form.List>
              {/* <Item name="otherOption" valuePropName="checked">
                <Checkbox>{`Include "other" option`}</Checkbox>
              </Item> */}
            </>
          )}

          {["Dropdown", "RadioButtons", "Checkboxes"].includes(
            watchOptionsType
          ) && (
            <>
              <div className={styles[watchOptionsType]}></div>
              <Form.Item name="defaultValue" label="Default Option">
                <Select
                  options={watchOptionsList?.map((option: any) => ({
                    label: option?.option,
                    value: option?.option,
                  }))}
                  value={currentDefaultOption}
                  onChange={handleDefaultOptionChange}
                  mode={
                    watchOptionsType === "Checkboxes" ? "multiple" : undefined
                  }
                />
              </Form.Item>
            </>
          )}

          <Button
            type="link"
            className={styles["delete-question"]}
            icon={<Micons icon="delete" />}
            onClick={() => {
              form.setFieldsValue({
                optionsList: [], // Clear the optionsList field in the form
              });
              handleQuestionDelete();
            }}
          >
            Delete Question
          </Button>
        </Form>
      </Modal>
      <div className={styles["question-cont"]}>
        <div className={styles["question"]}>
          {" "}
          <span className={styles["fixed-index"]}>1.</span>
          <p>Name *</p>
          <Micons
            icon="edit"
            style={{ fontSize: 20 }}
            wrapperClassName={styles["settings-icon"]}
          />
        </div>
        <div className={styles["OneLine"]}></div>
        <div className={styles["question"]} style={{ marginTop: 20 }}>
          <span className={styles["fixed-index"]}>2.</span>
          <p>Email *</p>
        </div>
        <div className={styles["OneLine"]}></div>
      </div>
      {questions.map((el, index) => {
        const { type } = el;
        return (
          <div
            className={styles["question-cont"]}
            key={el.question}
            onClick={() => handleQuestionEdit(index)}
          >
            <span>{`${index + 3}.`}</span>
            <div className={styles["question"]}>
              {" "}
              <p>
                {el.question}
                {!el.isActive ? (
                  <span className={styles["default-switch"]}>OFF</span>
                ) : null}
              </p>
              <Micons
                icon="edit"
                style={{ fontSize: 20 }}
                wrapperClassName={styles["settings-icon"]}
              />
            </div>
            <div className={styles[type]}>
              {type === "Dropdown" && <p>Select...</p>}
              {!["OneLine", "MultipleLines", "Dropdown"].includes(type) &&
                el?.options?.map((option: any) => {
                  return (
                    <div
                      className={styles["option"]}
                      key={`${Math.random() * 1000} ${el.question}`}
                    >
                      <span className={styles["box"]}></span>
                      <span>{option}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        );
      })}
      <Button
        type="link"
        style={{ color: "#0069FF", margin: "10px 0px" }}
        icon={<Micons icon="add" />}
        onClick={() => {
          setIsEdit(false);
          setEditIndex(0);
          setCurrentDefaultOption(""); // Reset the currentDefaultOption for new question
          form.resetFields(); // Reset the form fields
          form.setFieldsValue({
            defaultValue: "", // Set defaultValue to an empty string for new question
          });
          setIsOpen(true);
        }}
      >
        Add question
      </Button>
      <div className={`${styles["submit-btn-cont"]}`}>
        <Button
          onClick={() => {
            setActiveKey("0");
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={handleSubmit} type="primary" loading={isSubmitting}>
          {mode === "edit" ? "Save and update" : "Next"}
        </Button>
      </div>
    </>
  );
};

export default InviteeQuestions;
