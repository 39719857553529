import EventsWrapper from "../../../components/eventsWrapper";
import CalendlyPublicForm from "../calendly-public-form";

const SchedulEvents = () => {
  return (
    <EventsWrapper>
      <CalendlyPublicForm />
    </EventsWrapper>
  );
};

export default SchedulEvents;
