import { Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

import { message } from "antd";

import AppContext from "./components/contexts/appContext";
import { IUser } from "./interfaces/user";
import { AuthRoute } from "./routes/auth-route";
import { PrivateRoute } from "./routes/private-route";
import Spinner from "./components/spinner";
import { getApiErrorMsg } from "./utils/object-util";
import { useQuery } from "./hooks/useQuery";

// Public Routes
import Login from "./pages/login";
// import Signup from "./pages/signup";
import GoogleOAuthRedirect from "./pages/googleOAuthRedirect";
import HomePage from "./pages/homepage";
import PaymentRedirect from "./pages/public/payments";

// Private Routes
import Events from "./pages/events";
import ScheduledEvents from "./pages/events/scheduledEvents";
import CreateEventTypes from "./pages/events/createEventTypes";
import Forms from "./pages/events/form";
import Availability from "./pages/availability";
import Profile from "./pages/profile";
import Payout from "./pages/payout";
import Integration from "./pages/integration";
import IntegrationRedirects from "./pages/integration/redirects";

// Coomon Routes
import SchedulEvents from "./pages/public/scheduleevents";
import AllEvents from "./pages/public/allevents";

import { loginTokenKey } from "./constants";

const App = () => {
  const { query } = useQuery();
  const isAutoscalLandingPage = query.get("client") === "autoscal-landing";
  const isProfilePage = query.get("client") === "autoscal-profile-demo";
  const profileToken = query.get("token") ?? undefined;

  const [user, setUser] = useState<IUser>();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [loadingUser, setLoadingUser] = useState(
    !!localStorage.getItem(loginTokenKey)
  );

  const getAuthUrl = async (type: string) => {
    try {
      const event = type === "stripe" ? "payout" : "event";
      const url = `${type}/auth-url?type=${event}`;
      const response = await axios.get(url);
      return response?.data?.url;
    } catch (error) {
      message.error({
        content: getApiErrorMsg(error),
        key: "error",
        duration: 2,
      });
    }
    return;
  };

  const loadCurrentUser = (hideLoader = false) => {
    const token = localStorage.getItem(loginTokenKey);
    if (token) {
      if (!hideLoader) {
        setLoadingUser(true);
      }
      axios
        .get(`users/me`)
        .then((response) => {
          const userResp = response?.data?.data;
          (async function () {
            if (userResp) {
              let googleAuthUrl;
              let zoomAuthUrl;
              let stripeAuthUrl;
              if (!userResp?.googleRefreshToken) {
                googleAuthUrl = await getAuthUrl("google-calendar");
              }
              if (!userResp?.zoomRefreshToken) {
                zoomAuthUrl = await getAuthUrl("zoom");
              }
              if (!userResp?.stripeId) {
                stripeAuthUrl = await getAuthUrl("stripe");
              }
              userResp.zoomAuthUrl = zoomAuthUrl;
              userResp.googleAuthUrl = googleAuthUrl;
              userResp.stripeAuthUrl = stripeAuthUrl;
            }
            setUser(userResp);
            setIsUserLoggedIn(true);
            setLoadingUser(false);
          })();
        })
        .catch(() => {
          setIsUserLoggedIn(false);
          setLoadingUser(false);
        });
    } else {
      setIsUserLoggedIn(false);
    }
  };

  dayjs.extend(updateLocale);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.updateLocale("en", { weekStart: 1 });

  useEffect(() => {
    loadCurrentUser();
  }, []);

  return (
    <AppContext.Provider
      value={{
        isUserLoggedIn,
        loadingUser,
        user,
        loadCurrentUser,
        setIsUserLoggedIn,
        isAutoscalLandingPage,
        isProfilePage,
        profileToken,
      }}
    >
      {loadingUser ? (
        <Spinner />
      ) : (
        <Routes>
          <Route path="/" element={<AuthRoute component={Login} />} />
          <Route path="/login" element={<AuthRoute component={Login} />} />
          {/* <Route path="/signup" element={<AuthRoute component={Signup} />} /> */}
          <Route
            path="/googleoauth"
            element={<AuthRoute component={GoogleOAuthRedirect} />}
          />
          {["/integrate-zoom", "/integrate-gmeet", "/integrate-stripe"].map(
            (el) => (
              <Route
                key={el}
                path={el}
                element={
                  <PrivateRoute component={IntegrationRedirects} hideLayout />
                }
              />
            )
          )}
          <Route path="/home" element={<PrivateRoute component={HomePage} />} />
          <Route path="/events" element={<PrivateRoute component={Events} />} />
          <Route
            path="/events/event-types"
            element={<PrivateRoute component={CreateEventTypes} />}
          />
          <Route
            path="/events/scheduled_events"
            element={<PrivateRoute component={ScheduledEvents} />}
          />
          <Route
            path="/events/form"
            element={<PrivateRoute component={Forms} />}
          />
          <Route
            path="/availability"
            element={<PrivateRoute component={Availability} />}
          />
          <Route path="/payout" element={<PrivateRoute component={Payout} />} />
          <Route
            path="/profile"
            element={<PrivateRoute component={Profile} />}
          />
          <Route
            path="/integration"
            element={<PrivateRoute component={Integration} />}
          />

          <Route path="/payments" element={<PaymentRedirect />} />
          <Route path="/:userlink/:eventlink" element={<SchedulEvents />} />
          <Route path="/:userlink" element={<AllEvents />} />
        </Routes>
      )}
    </AppContext.Provider>
  );
};

export default App;
