import styles from "./authLayout.module.scss";

interface Props {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: Props) => {
  return (
    <div className={styles["outer-wrapper"]}>
      <div className={styles["auth-layout-wrapper"]}>
        {/* <div className={styles["left-content"]}></div> */}
        <div className={styles["right-content"]}>{children}</div>
      </div>
    </div>
  );
};

export default AuthLayout;
