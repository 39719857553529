import { Spin, SpinProps } from "antd";

import styles from "./spinner.module.scss";

const Spinner = (props: SpinProps) => {
  return (
    <div className={styles["spinner-wrapper"]}>
      <Spin {...props} />
    </div>
  );
};

export default Spinner;
