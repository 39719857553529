import axios from "axios";
import { message } from "antd";

import { loginTokenKey, profileTokenKey } from "../constants";

axios.interceptors.request.use(
  (config: any) => {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
    };

    const token = localStorage.getItem(loginTokenKey);
    const profileToken = localStorage.getItem(profileTokenKey);

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    if (profileToken) {
      config.headers["Authorization"] = "Bearer " + profileToken;
    }
    if (profileToken) {
      config.url = `${process.env.REACT_APP_JOB_SERVICE_BASE_URL}${config.url}`;
      localStorage.setItem(profileTokenKey, "");
    } else {
      config.url = `${process.env.REACT_APP_API_HOST}${config.url}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      message.error({
        content: "User not unauthorized!",
        key: "unauthorized",
        duration: 2,
      });
      localStorage.clear();
      // window.location("/");
    }
    throw error;
  }
);
