import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Radio, Tooltip, Button, Select } from "antd";
// import axios from "axios";

import { Input } from "../../../components/form-fields/input";
import { TextArea } from "../../../components/form-fields/text-area";
import { eventColors } from "../../../constants";
import { useAppContext } from "../../../components/contexts/appContext";
// import { getApiErrorMsg } from "../../../utils/object-util";

import styles from "./form.module.scss";

const { Item } = Form;

interface IFormStepOneProps {
  type: string | null;
  mode: string | null;
  onSubmit: (data: any) => void;
  formData: any;
  setActiveKey: (arg: string) => void;
  isSubmitting: boolean;
  id: string | null;
}

const EventStepOne = ({
  id,
  type,
  mode,
  onSubmit,
  formData,
  setActiveKey,
  isSubmitting,
}: IFormStepOneProps) => {
  const { user } = useAppContext();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const watchLocationType = Form.useWatch("locationType", form);
  const watchName = Form.useWatch("name", form);
  const watchLink = Form.useWatch("link", form);
  const [isLinkTouched, setIsLinkTouched] = useState(mode === "edit");

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue(formData);
    }
  }, [mode]);

  useEffect(() => {
    const formFields = localStorage.getItem("eventForm");
    if (formFields) {
      form.setFieldsValue(JSON.parse(formFields));
      localStorage.removeItem("eventForm");
      localStorage.removeItem("integration-callback-event-type");
      localStorage.removeItem("integration-callback-event-id");
      setActiveKey("1");
    }
  }, []);

  useEffect(() => {
    if (watchLocationType) {
      form.validateFields(["locationType"]);
    }
  }, [user?.zoomRefreshToken, user?.googleRefreshToken, watchLocationType]);

  useEffect(() => {
    const newStr = watchName
      ?.trim()
      .replaceAll(" ", "-")
      .toLowerCase()
      .replace(/[^a-z0-9_-]/g, "");
    if (!isLinkTouched && watchName) {
      form.setFieldValue("link", newStr);
    }
  }, [watchName]);

  function handleLinkBlur() {
    if (watchLink) {
      setIsLinkTouched(true);
    }
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Form
            layout="vertical"
            form={form}
            onFinish={onSubmit}
            initialValues={{ color: "#8247F5" }}
            requiredMark={false}
            disabled={isSubmitting}
          >
            <Item
              name="name"
              label="Event Name"
              rules={[{ required: true, message: "Event name is required" }]}
            >
              <Input />
            </Item>
            <Item
              name="locationType"
              label="Location"
              rules={[
                {
                  validator: async (_, value) => {
                    if (
                      (value === "Zoom" && !user?.zoomRefreshToken) ||
                      (value === "GoogleMeet" && !user?.googleRefreshToken)
                    ) {
                      const isZoom = value === "Zoom";
                      return Promise.reject(
                        <>
                          <span>{`${
                            isZoom ? "Zoom" : "Google Meet"
                          } is not integrated.`}</span>
                          <Button
                            type="link"
                            style={{ padding: 0 }}
                            onClick={() => {
                              localStorage.setItem(
                                "eventForm",
                                JSON.stringify(form.getFieldsValue())
                              );
                              if (type)
                                localStorage.setItem(
                                  "integration-callback-event-type",
                                  type
                                );
                              if (id)
                                localStorage.setItem(
                                  "integration-callback-event-id",
                                  id
                                );
                              window.open(
                                isZoom
                                  ? user?.zoomAuthUrl
                                  : user?.googleAuthUrl,
                                "_self"
                              );
                            }}
                          >
                            {`Click here to integrate your ${
                              isZoom ? "Zoom" : "Google Meet"
                            } account.`}
                          </Button>
                        </>
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Select
                options={[
                  { label: "In Person", value: "InPerson" },
                  { label: "Google Meet", value: "GoogleMeet" },
                  { label: "Zoom  ", value: "Zoom" },
                ]}
                placeholder="Add a location"
              />
            </Item>
            {watchLocationType === "InPerson" && (
              <Item
                name="location"
                label="Address"
                rules={[{ required: true, message: "Address is requireds" }]}
              >
                <Input />
              </Item>
            )}
            <Item
              name="description"
              label="Description/Instructions"
              rules={[{ required: true, message: "Description is required" }]}
            >
              <TextArea
                placeholder="Write a summary and any details should know about the event"
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Item>
            <Item
              name="link"
              label={
                <div>
                  <p>Event Link</p>
                  <p>{`calendar.autoscal.com/${user?.link}/`}</p>
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Link is required",
                },
                {
                  pattern: /^[a-z0-9_-]+$/,
                  message:
                    "Invalid Link. Please use lowercase only 'a-z', '0-9', '-', or '_' characters.",
                },
              ]}
              className={styles["link-label"]}
            >
              <Input onBlur={handleLinkBlur} />
            </Item>
            <Item name="color" label="Event Color">
              <Radio.Group className={styles["color-options"]}>
                {eventColors.map(
                  ({ name, hex }: { name: string; hex: string }) => (
                    <Tooltip
                      key={name}
                      title={name}
                      placement="bottom"
                      arrow={false}
                      overlayInnerStyle={{
                        background: "none",
                        color: "black",
                        boxShadow: "none",
                      }}
                    >
                      <Radio value={hex} style={{ backgroundColor: hex }} />
                    </Tooltip>
                  )
                )}
              </Radio.Group>
            </Item>
          </Form>
        </Col>
      </Row>
      <div className={styles["submit-btn-cont"]}>
        <Button
          onClick={() => {
            if (mode === "edit") {
              setActiveKey("0");
            } else {
              navigate("/events/event-types");
            }
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button onClick={form.submit} type="primary" loading={isSubmitting}>
          {mode === "edit" ? "Save and update" : "Next"}
        </Button>
      </div>
    </>
  );
};

export default EventStepOne;
