import styles from "./wrapper.module.scss";

interface IWrapperProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const Wrapper = ({ children, className, style }: IWrapperProps) => {
  return (
    <div className={`${styles.wrapper} ${className || ""}`} style={style || {}}>
      {children}
    </div>
  );
};

export default Wrapper;
