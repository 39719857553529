import { useState, useEffect } from "react";
import { Row, Col, Button, message, Popover, Switch, Modal, Empty } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { Input } from "../../../components/form-fields/input";
import { useAppContext } from "../../../components/contexts/appContext";
import { eventTypeMap } from "../../../constants";
import { debounceMethod } from "../../../utils/function-util";
import Micons from "../../../components/customAntd/micons";
import Spinner from "../../../components/spinner";
import { getApiErrorMsg, getApiResponseMsg } from "../../../utils/object-util";

import styles from "./eventTypes.module.scss";

export interface IEvents {
  id: string;
  name: string;
  type: string;
  duration: number;
  color: string;
  isActive: boolean;
  link: string;
  questions: any[];
}
const EventTypes = () => {
  const navigate = useNavigate();

  const [events, setEvents] = useState<IEvents[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [currentEvent, setCurrentEvent] = useState<IEvents | null>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleteing, setIsDeleting] = useState<boolean>(false);

  const { user } = useAppContext();

  useEffect(() => {
    const filter = {
      offset: 0,
      limit: 100,
      orderBy: {
        createdAt: "asc",
      },
      filterCondition: {
        ...(searchValue && { search: searchValue }),
      },
    };
    setLoading(true);
    axios
      .post(`events/all`, filter)
      .then((response) => {
        setEvents(response.data?.data || []);
      })
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "error",
          duration: 2,
        });
      })
      .finally(() => setLoading(false));
  }, [searchValue]);

  const onSearch = debounceMethod((e: any) => {
    setSearchValue(e.target.value);
  });

  const toggleActive = (event: IEvents, isActive: boolean) => {
    axios
      .put(`events/${event.id}`, { isActive })
      .then((response) => {
        if (response.data.data) {
          setEvents((prev) => {
            return prev?.map((el) => {
              return el.id === response.data.data.id ? response.data.data : el;
            });
          });
          setIsOpen(false);
        }
      })
      .catch((err) => {
        message.error({
          content: getApiErrorMsg(err),
          key: "error",
          duration: 2,
        });
      })
      .finally(() => {
        setCurrentEvent(null);
      });
  };

  const deleteEvent = () => {
    setIsDeleting(true);
    if (currentEvent) {
      axios
        .delete(`events/${currentEvent.id}`)
        .then((response) => {
          if (response.data?.data) {
            setEvents((prev) =>
              prev?.filter((el: any) => el.id !== response.data.data.id)
            );
            setCurrentEvent(null);
          }
          message.success({
            content: getApiResponseMsg(response),
            key: "success",
            duration: 2,
          });
        })
        .catch((err) => {
          message.error({
            content: getApiErrorMsg(err),
            key: "delete-error",
            duration: 2,
          });
        })
        .finally(() => {
          setIsDeleting(false);
          setIsDeleteModalOpen(false);
        });
    }
  };

  const getPopoverContent = (event: IEvents) => {
    return (
      <div className={styles["popover-content"]}>
        <div className={styles["options"]}>
          <div
            className={styles["action"]}
            onClick={() => {
              navigate(
                `/events/form?mode=edit&type=${event.type}&&id=${event.id}`,
                {
                  state: {
                    event: event,
                  },
                }
              );
            }}
          >
            <Micons icon="edit" />
            <p>Edit</p>
          </div>
          {/* <div className={styles["action"]}>
            <Micons icon="copy" />
            <p>Clone</p>
          </div> */}
          <div
            className={styles["action"]}
            onClick={() => {
              setIsDeleteModalOpen(true);
              handleOpenChange(false);
            }}
          >
            <Micons icon="delete" />
            <p>Delete</p>
          </div>
        </div>
        <div className={styles["toggle-container"]}>
          <p>On/off</p>
          <Switch
            checked={event.isActive}
            onChange={(val) => {
              toggleActive(event, val);
            }}
          />
        </div>
      </div>
    );
  };

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
  };

  return (
    <>
      <Modal
        title={`Delete ${currentEvent?.name}?`}
        open={isDeleteModalOpen}
        okText="Delete"
        cancelText="Cancel"
        cancelButtonProps={{
          onClick: () => {
            setIsDeleteModalOpen(false);
          },
          disabled: isDeleteing,
        }}
        okButtonProps={{
          onClick: () => {
            deleteEvent();
          },
          disabled: isDeleteing,
        }}
        className={styles["delete-confirmation-modal"]}
        closeIcon={null}
        width={400}
      >
        Users will be unable to schedule further meetings with deleted event
        types. Meetings previously scheduled will not be affected.
      </Modal>
      <div className={styles.wrapper}>
        <div className={styles.filter}>
          <Input
            style={{ minWidth: 300 }}
            placeholder="Filter"
            onChange={onSearch}
            prefix={<Micons icon="search" />}
          />

          <Button
            type="primary"
            icon={<Micons icon="add" />}
            onClick={() => {
              navigate("/events/event-types");
            }}
          >
            New Event Type
          </Button>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {events.length > 0 ? (
              <Row gutter={[30, 30]}>
                {events.map((event) => {
                  const { id, name, duration, type, color, isActive } = event;
                  return (
                    <Col key={id} xs={12} lg={8}>
                      <div
                        className={`${styles["event-card"]} ${
                          !isActive ? styles.disabled : ""
                        }`}
                      >
                        <div
                          className={styles["border-cap"]}
                          style={{ borderColor: isActive ? color : "#B2B2B2" }}
                        ></div>
                        <div className={styles.body}>
                          <div className={styles["setting"]}>
                            {/* <Checkbox /> */}

                            <Popover
                              content={getPopoverContent(event)}
                              open={isOpen && id === currentEvent?.id}
                              onOpenChange={handleOpenChange}
                              overlayClassName={styles["card-settings-popover"]}
                              placement="bottomRight"
                              trigger="click"
                              arrow={false}
                            >
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentEvent(
                                    id === currentEvent?.id ? null : event
                                  );
                                }}
                                className={styles["settings-icon"]}
                              >
                                <Micons icon="settings" />
                                <Micons
                                  icon="arrow_drop_down"
                                  className={`${
                                    currentEvent && currentEvent.id === id
                                      ? styles["rotate"]
                                      : styles["check"]
                                  }`}
                                />
                              </div>
                            </Popover>
                          </div>
                          <p className={styles.name}>{name}</p>
                          <p className={styles.duration}>
                            {`${
                              duration <= 60
                                ? `${duration} mins`
                                : `${Math.floor(duration / 60)} hrs ${
                                    duration % 60 > 0
                                      ? `${duration % 60} mins`
                                      : ""
                                  }`
                            } , ${eventTypeMap[type]}
                    `}
                          </p>
                          <a
                            href={`${window.location.origin}/${user?.link}/${event?.link}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            View booking page
                          </a>
                        </div>
                        <div className={styles.footer}>
                          <Button
                            type="link"
                            className={styles["copy-link"]}
                            disabled={!isActive}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${window.location.origin}/${user?.link}/${event?.link}`
                              );
                              message.success({
                                content: "Link copied...",
                                key: "success",
                                duration: 2,
                              });
                            }}
                          >
                            <Micons icon="copy" style={{ fontSize: 16 }} />
                            <p>Copy link</p>
                          </Button>
                          <Button
                            onClick={() => {
                              if (!isActive) {
                                toggleActive(event, true);
                              }
                            }}
                          >
                            {isActive ? "Share" : "Turn On"}
                          </Button>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <Empty description="No Events Found" style={{ marginTop: 100 }} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default EventTypes;
