import { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { message } from "antd";
import axios from "axios";

import { useAppContext } from "../../components/contexts/appContext";
import Spinner from "../../components/spinner";
import { profileTokenKey } from "../../constants";

const GoogleOAuthRedirect = () => {
  const [searchParams] = useSearchParams();
  const { loadCurrentUser, profileToken } = useAppContext();
  const navigate = useNavigate();
  const code = searchParams.get("code");

  if (profileToken) {
    localStorage.setItem(profileTokenKey, profileToken);
  }

  useEffect(() => {
    if (code) {
      axios.post("google/login", { code }).then((resp) => {
        if (resp.data?.data?.token) {
          localStorage.setItem("user-access-token", resp.data.data.token);
          loadCurrentUser();
          message.success({
            content: "Login successful",
            key: "login",
            duration: 2,
          });
        }
      });
    } else {
      navigate("/login");
    }
  }, [code]);

  return <Spinner />;
};

export default GoogleOAuthRedirect;
