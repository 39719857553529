import { useState, useEffect, useMemo } from "react";
import {
  useSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { Result } from "antd";

import Micons from "../../../components/customAntd/micons";
import { getApiErrorMsg } from "../../../utils/object-util";
import Spinner from "../../../components/spinner";
import { useAppContext } from "../../../components/contexts/appContext";
import { useQuery } from "../../../hooks/useQuery";

import styles from "./payments.module.scss";

const PaymentsRedirect = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentData, setPaymentData] = useState<any>();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { query } = useQuery();
  const { isAutoscalLandingPage, isProfilePage } = useAppContext();

  const code = useMemo(() => {
    return searchParams.get("code");
  }, []);

  const sendMsgToWindowParent = () => {
    if (window.parent) {
      window.parent.postMessage("Event Scheduled", "*");
    }
    if (isProfilePage) {
      const event = location?.state?.details?.event;
      return navigate(`/${event.user.link}/${event.link}?${query.toString()}`, {
        replace: true,
      });
    }
  };

  useEffect(() => {
    if (code) {
      setIsLoading(true);
      axios
        .put("public/bookings", { code })
        .then((response) => {
          if (response.data?.data) {
            setPaymentData(response.data.data);
            sendMsgToWindowParent();
          }
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log("err --->>>>", getApiErrorMsg(err)))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
      if (location?.state?.details) {
        setPaymentData({
          ...location.state.details,
          notPayable: true,
          status: "Confirmed",
        });
        sendMsgToWindowParent();
      }
    }
  }, []);
  return isLoading ? (
    <Spinner />
  ) : isAutoscalLandingPage ? (
    <div className={styles["confirmation-wrapper"]}>
      <p className={styles["confirmation-title"]}>
        Thank you for scheduling a demo with us!
      </p>
      <p>
        {`Our team will get in touch with you at ${paymentData.customerEmail}.`}
      </p>
    </div>
  ) : (
    <div
      className={styles["container"]}
      style={{ alignItems: isLoading ? "center" : "flex-start" }}
    >
      <Result
        status={
          paymentData
            ? paymentData.status === "Confirmed"
              ? "success"
              : "error"
            : "500"
        }
        title={
          paymentData
            ? paymentData.status === "Confirmed" && !paymentData.notPayable
              ? "Payment Confirmed"
              : paymentData.notPayable
              ? "Booking Confirmed"
              : "Payment Cancelled"
            : ""
        }
        subTitle={!paymentData ? "Sorry, something went wrong." : ""}
        className={styles["result-cont"]}
        extra={
          <>
            {paymentData?.status === "Confirmed" ? (
              <ul className={styles["details"]}>
                {!paymentData.notPayable && (
                  <>
                    <li>
                      <span>Amount</span>
                      <p>
                        {paymentData.event.currency === "INR" ? (
                          <span>&#8377;</span>
                        ) : (
                          <span>&#36;</span>
                        )}
                        {paymentData.payment.amount}
                      </p>
                    </li>
                    <li>
                      <span>Payment Id</span>
                      <span>{paymentData.payment.referenceNumber}</span>
                    </li>
                  </>
                )}
                <li>
                  <span>Name</span>
                  <span>{paymentData.customerName}</span>
                </li>
                <li>
                  <span>Email</span>
                  <span>{paymentData.customerEmail}</span>
                </li>
                {paymentData.notPayable && (
                  <li>
                    <span>Event Name</span>
                    <span>{paymentData.event.name}</span>
                  </li>
                )}
                <li>
                  <span>Slot Date and Time:</span>
                  <span>
                    {dayjs(paymentData.slotTime)
                      .tz(paymentData.timezone)
                      .format("DD-MM-YYYY hh:mm A")}
                  </span>
                </li>
                <li>
                  <span>TimeZone:</span>
                  <span>{paymentData.timezone}</span>
                </li>
              </ul>
            ) : (
              <></>
            )}
            <Link
              to={
                paymentData
                  ? `/${paymentData.event.user.link}/${paymentData.event.link}`
                  : "/"
              }
            >
              <Micons icon="arrow_back" type="filled" />
              <span>{paymentData ? "Back to event page" : "Back to home"}</span>
            </Link>
          </>
        }
      />
    </div>
  );
};

export default PaymentsRedirect;
