import { useAppContext } from "../contexts/appContext";

import styles from "./events.module.scss";

interface IWrapperProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
}

const EventsWrapper = ({ children, className, style }: IWrapperProps) => {
  const { isAutoscalLandingPage, isProfilePage } = useAppContext();

  return (
    <div
      className={`${styles.wrapper} ${className || ""} ${
        isAutoscalLandingPage ? styles["wrapper-landing"] : ""
      } ${isProfilePage ? styles["wrapper-profile"] : ""}`}
      style={style || {}}
    >
      <div className={styles["event-container"]}>
        {!isAutoscalLandingPage && !isProfilePage && (
          <div className={styles["ribbon"]}>
            <span>CALENDAR</span>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default EventsWrapper;
