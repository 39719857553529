import { Navigate } from "react-router-dom";

import PrivateLayout from "../components/layouts/privateLayout";
import { useAppContext } from "../components/contexts/appContext";

interface PrivateRouteProps {
  component: React.FC;
  hideLayout?: boolean;
}

export const PrivateRoute = ({
  component: Component,
  hideLayout,
}: PrivateRouteProps) => {
  const { isUserLoggedIn, setIsUserLoggedIn } = useAppContext();

  const handleLogout = () => {
    localStorage.clear();
    setIsUserLoggedIn(false);
  };

  if (isUserLoggedIn) {
    return hideLayout ? (
      <Component />
    ) : (
      <PrivateLayout handleLogout={handleLogout}>
        <Component />
      </PrivateLayout>
    );
  } else {
    return <Navigate to="/" replace />;
  }
};
